<!-- 설비 유형 상세 (EquipTypeDetail) -->
<template>
    <vvo v-slot="v">
        <div class="detail-container" v-if="detailInfo">
            <!-- 2.상세 정보 제목 영역 -->
            <div class="detail-header">
                <div class="col detail-title">
                    <span class="title" v-if="viewTitle">{{ viewTitle }}</span>
                    <span class="badge ptAddr"> {{ detailInfo.ptName }} </span>
                    <span class="badge ptIdx"> {{ detailInfo.ptIdx }} </span>
                    <div>
                        <span class="badge option"> {{ ptTypeCode }} </span>
                        <span class="badge option"> {{ energySourceCode }} </span>
                        <span class="badge option"> {{ unitSmallCode }} </span>
                        <span class="badge option"> {{ dataSourceType }} </span>
                    </div>
                </div>
                <div v-if="!isNewMode" class="detail-control">
                    <span
                        v-if="!global"
                        class="status-alert-icon"
                        @click="handleIconClick('delete', $event)"
                        v-b-tooltip.hover
                        :title="$t('관제점 삭제')"
                    >
                        <i class="fas fa-trash-alt" aria-hidden="true"></i>
                    </span>
                    <!-- <span
                        v-if="!global"
                        class="status-icon"
                        @click="handleIconClick('newData', $event)"
                        v-b-tooltip.hover
                        :title="$t('관제점 추가')"
                    >
                        <i class="fas fa-plus-square" aria-hidden="true"></i>
                    </span> -->
                    <span
                        v-if="!global"
                        class="status-icon"
                        @click="handleIconClick('addClone', $event)"
                        v-b-tooltip.hover
                        :title="$t('관제점 복제')"
                    >
                        <i class="fas fa-clone" aria-hidden="true"></i>
                    </span>
                    <span
                        class="status-icon"
                        @click="handleIconClick('trend', $event)"
                        v-b-tooltip.hover
                        :title="$t('트랜드 조회')"
                    >
                        <i class="fas fa-chart-area" aria-hidden="true"></i>
                    </span>
                    <span
                        v-if="!global"
                        class="status-icon"
                        :class="{ active: isEditMode }"
                        @click="handleIconClick('mode', $event)"
                        v-b-tooltip.hover
                        :title="$t('정보 수정')"
                    >
                        <i class="fas fa-edit" aria-hidden="true"></i>
                    </span>
                    <!-- <b-form-checkbox switch v-model="isEditMode" size="lg" @change="changeEditMode"></b-form-checkbox> -->
                </div>
            </div>

            <!-- 3.상세 정보 내용 영역 -->
            <div class="detail-content" :style="{ height: customHeight !== '' ? customHeight : '' }">
                <!-- 2.실시간 상태값 표시 -->
                <div class="detail-status" v-if="isViewMode">
                    <div class="d-col">
                        <div class="status-title">현재값</div>
                        <div class="status-value">{{ ptVal }}</div>
                    </div>

                    <div class="d-col">
                        <div class="status-title">수집값</div>
                        <div class="status-value">{{ ptOrg }}</div>
                    </div>

                    <div class="d-col">
                        <div class="status-title">이전값</div>
                        <div class="status-value">{{ ptPre }}</div>
                    </div>

                    <div class="d-col">
                        <div class="status-title">갱신 시간</div>
                        <div :class="['status-value', { 'out-of-date': isOutOfDate }]">
                            {{ detailInfo.updDt ? detailInfo.updDt.passedTime() : "-" }}
                        </div>
                    </div>
                    <!-- <div class=" row" style="justify-content: space-around; align-items: center" v-if="!isNewMode">
                </div> -->
                </div>

                <div
                    class="detail-status"
                    v-if="!isViewMode"
                    style="padding-left: 10px; padding-right: 10px; text-align: left"
                >
                    관제점 등록 기능은 주로 논리 관제점을 추가할 때 주로 사용됩니다. 논리 관제점은 가상의 관제점으로
                    계산식을 설정하여 계산값을 지속적으로 저장하고 집계할 수 있습니다. 수집 및 제어를 위한 물리 관제점은
                    수집 네트워크 또는 설비 관리 기능을 사용하면 간편하게 구성할 수 있습니다.
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-4">
                            <label>
                                <trans>관제점 주소</trans>
                            </label>
                            <!-- |not-start-number  숫자로 시작할 수 없습니다. -->
                            <!-- <vvp rules="required|min:8|max:32|special" v-slot="{ errors, classes }"
                                name="관제점 주소">
                                <input type="text" :readonly="!isEditMode" class="form-control" :class="classes"
                                    :placeholder="$t('관제점 주소를 입력하세요.')" v-model="detailInfo.ptAddr" />
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                            </vvp> -->
                            <valid-input
                                :vid="'관제점 주소'"
                                :classList="'form-control'"
                                :inputType="'text'"
                                :inputValue.sync="detailInfo.ptAddr"
                                :placeholder="$t('관제점 주소')"
                                :rules="rules.POINT_ADDRESS_RULE"
                                :errorMessage="validMessage.POINT_ADDRESS_VALID_MESSAGE"
                                :disabled="!isEditMode"
                            ></valid-input>
                        </div>
                        <div class="col-md-2">
                            <label for="isVirtual">
                                <trans>관제점 구분</trans>
                            </label>
                            <!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="detailInfo.enabled" /> -->
                            <select
                                v-model="detailInfo.isVirtual"
                                class="form-control"
                                id="isVirtual"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.isVirtual"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-6">
                            <label>
                                <trans>관리 옵션</trans>
                            </label>
                            <div class="btn-group d-flex justify-content-between align-items-center">
                                <button
                                    type="button"
                                    class="btn"
                                    :class="agrgtnStatus ? 'btn-primary' : 'btn-secondary'"
                                    @click="isAggregationEnabled"
                                    :disabled="!isEditMode"
                                >
                                    <trans>집계</trans>
                                </button>
                                <button
                                    type="button"
                                    class="btn"
                                    :class="covStatus ? 'btn-primary' : 'btn-secondary'"
                                    @click="isCovEnabled"
                                    :disabled="!isEditMode"
                                >
                                    <trans>COV</trans>
                                </button>
                                <button
                                    type="button"
                                    class="btn"
                                    :class="ignoreZeroStatus ? 'btn-primary' : 'btn-secondary'"
                                    @click="isIgnoreZeroEnabled"
                                    :disabled="!isEditMode"
                                >
                                    <trans>0값 무시</trans>
                                </button>
                                <button
                                    type="button"
                                    class="btn"
                                    :class="enabledStatus ? 'btn-primary' : 'btn-secondary'"
                                    @click="isInUseEnabled"
                                    :disabled="!isEditMode"
                                >
                                    <trans>사용중</trans>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <label>
                                <trans>관제점명</trans>
                            </label>
                            <!--  TODO : 관제점명 체크 로직 정리 필요함. -->
                            <!-- <vvp rules="required|min:2|max:100" v-slot="{ errors, classes }" name="관제점명">
                                <input type="text" v-focus class="form-control" :class="classes"
                                    :placeholder="$t('관제점명을 입력하세요.')" v-model="detailInfo.ptName"
                                    :readonly="!isEditMode" />
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                            </vvp> -->
                            <valid-input
                                :vid="'관제점명'"
                                :classList="'form-control'"
                                :inputType="'text'"
                                :inputValue.sync="detailInfo.ptName"
                                :placeholder="$t('관제점명')"
                                :rules="rules.COMMON_NAME_RULE"
                                :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                                :disabled="!isEditMode"
                            ></valid-input>
                        </div>
                        <div class="col-lg-3">
                            <label>
                                <trans>관제점 그룹</trans>
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('관제점 그룹을 입력하세요.')"
                                v-model="detailInfo.ptGroup"
                                :readonly="!isEditMode"
                            />
                        </div>

                        <div class="col-md-3">
                            <label for="applyZoneIdx">
                                <trans>구역</trans>
                            </label>
                            <select
                                v-model="detailInfo.applyZoneIdx"
                                class="form-control"
                                id="applyZoneIdx"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option v-for="zone in $store.state.zoneInfo" :key="zone.value" :value="zone.value">
                                    {{ zone.text }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2">
                            <label for="rmk">
                                <trans>관제점 설명</trans>
                            </label>
                        </div>
                        <div class="col-md-10">
                            <!-- <textarea class="form-control" :placeholder="$t('관제점 설명')" v-model="detailInfo.rmk"
                                :readonly="!isEditMode" rows="3" style="resize: none;"></textarea> -->
                            <valid-textarea
                                :inputType="'text'"
                                :inputValue.sync="detailInfo.rmk"
                                :rows="3"
                                :NonStatus="true"
                                :classList="'desc-ctn form-control'"
                                :rules="rules.COMMON_DESC_RULE"
                                :disabled="!isEditMode"
                            >
                            </valid-textarea>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                            <label for="purpsUseCode">
                                <trans>활용 목적</trans>
                            </label>
                            <!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="detailInfo.enabled" /> -->
                            <select
                                v-model="detailInfo.purpsUseCode"
                                class="form-control"
                                id="purpsUseCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.purpsUseCode"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="dataSourceCode">
                                <trans>데이터 획득 유형</trans>
                            </label>
                            <select
                                v-model="detailInfo.dataSourceCode"
                                class="form-control"
                                id="dataSourceCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.dataSourceType"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="dataSourceCode">
                                <trans>데이터 유형</trans>
                            </label>
                            <select
                                v-model="detailInfo.dataTypeCode"
                                class="form-control"
                                id="dataSourceCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.dataType"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="valueTypeCode">
                                <trans>값 유형</trans>
                            </label>
                            <select
                                v-model="detailInfo.valueTypeCode"
                                class="form-control"
                                id="valueTypeCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.valueTypeCode"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <label for="energySourceCode">
                                <trans>사용 에너지</trans>
                            </label>
                            <!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="detailInfo.enabled" /> -->
                            <select
                                v-model="detailInfo.energySourceCode"
                                class="form-control"
                                id="energySourceCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.energySourceCode"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <label for="energySplyTypeCode">
                                <trans>에너지 공급원 구분</trans>
                            </label>
                            <!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="detailInfo.enabled" /> -->
                            <select
                                v-model="detailInfo.energySplyTypeCode"
                                class="form-control"
                                id="energySplyTypeCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.energySplyTypeCode"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <label for="itemUseageCode">
                                <trans>사용 용도</trans>
                            </label>
                            <!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="detailInfo.enabled" /> -->
                            <select
                                v-if="detailInfo.isVirtual == '1'"
                                v-model="detailInfo.itemUseageCode"
                                class="form-control"
                                id="itemUseageCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.locPointUsage"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                            <select
                                v-else
                                v-model="detailInfo.itemUseageCode"
                                class="form-control"
                                id="itemUseageCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.phscPointUsage"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-4">
                            <!-- TODO: 단위에 W 가 있는데    W (unknown)  으로 표시됨 -->
                            <label for="unitLargeCode">
                                <trans>계측 대상</trans>
                            </label>
                            <select
                                v-model="detailInfo.unitLargeCode"
                                id="unitLargeCode"
                                class="form-control"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="value in $store.state.unitTypes"
                                    :key="value.value"
                                    :value="value.value"
                                    id="unitLargeCode"
                                >
                                    {{ value.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <!-- TODO: 단위에 W 가 있는데    W (unknown)  으로 표시됨 -->
                            <label for="itemPropCode">
                                <trans>계측값 유형</trans>
                            </label>
                            <select
                                v-model="detailInfo.itemPropCode"
                                id="itemPropCode"
                                class="form-control"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="value in $store.state.itemPropCode"
                                    :key="value.value"
                                    :value="value.value"
                                    id="itemPropCode"
                                >
                                    {{ value.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <!-- TODO: 단위 삭제하고 저장하면, 삭제된 채로 저장되지 않음 -->
                            <label for="unitSmallCode">
                                <trans>계측값 단위</trans>
                            </label>
                            <select
                                v-model="detailInfo.unitSmallCode"
                                id="unitSmallCode"
                                class="form-control"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.unitCodes[detailInfo.unitLargeCode]"
                                    :key="code.value"
                                    :value="code.value"
                                    id="unitSmallCode"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <label for="purpsUseCode">
                                <trans>집계 유형</trans>
                            </label>
                            <!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="detailInfo.enabled" /> -->
                            <select
                                v-model="detailInfo.ptTypeCode"
                                class="form-control"
                                id="ptTypeCode"
                                :disabled="!isEditMode"
                            >
                                <option :value="null">{{ $t("선택") }}</option>
                                <option
                                    v-for="code in $store.state.commonCodes.ptTypeCode"
                                    :key="code.value"
                                    :value="code.value"
                                >
                                    {{ code.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>
                                <trans>최소값</trans>
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                :readonly="!isEditMode"
                                :placeholder="$t('최대값을 입력하세요.')"
                                v-model="detailInfo.filterMinValue"
                            />
                        </div>
                        <div class="col-md-3">
                            <label>
                                <trans>최대값</trans>
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                :readonly="!isEditMode"
                                :placeholder="$t('최대값을 입력하세요.')"
                                v-model="detailInfo.filterMaxValue"
                            />
                        </div>
                        <div class="col-md-3">
                            <label>
                                <trans>오버플로우</trans>
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                :readonly="!isEditMode"
                                :placeholder="$t('오버플로우값을 입력하세요.')"
                                v-model="detailInfo.fixValue"
                                v-b-tooltip.hover
                                :title="$t('Min, Max 범위를 벗어난 경우에 사용할 값을 지정합니다. 빈값도 가능합니다.')"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- 3.하단 영역 -->
            <div class="detail-bottom">
                <!-- 3.1 자료 갱신 정보  -->
                <div class="detail-bottom-info" v-if="detailInfo">
                    <span>{{ `관리자: ${detailInfo.regWorker ? detailInfo.regWorker : "-"}` }}</span>
                    <span>{{ `생성일자: ${detailInfo.regDate ?? "-"}` }}</span>
                    <span>{{ `수정일자: ${detailInfo.modDate ?? "-"}` }}</span>
                </div>
                <!-- 3.2 하단 제어 버튼 -->
                <!-- saveData -->
                <!-- @click="handleSave(detailInfo, isEditMode)" -->
                <div class="detail-bottom-buttons">
                    <button
                        type="button"
                        class="btn btn-primary detail-btn"
                        v-if="isEditMode"
                        @click="onValidate(v, saveData)"
                    >
                        저장
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary detail-btn"
                        v-if="detailInfo"
                        @click="handleClose($event)"
                    >
                        닫기
                    </button>
                </div>
            </div>

            <!-- Modal -->
            <PointTrendModal ref="PointTrendModal" :title="modalTitle" :pointInfo="detailInfo" />
        </div>
    </vvo>
</template>

<script>
import PointTrendModal from "./modal/PointTrendModal.vue";

import backEndApi from "@src/api/backEndApi";
import moment from "moment";

import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        PointTrendModal,
    },
    // props: ['title', 'ptIdx'],
    props: {
        title: { type: String, default: "" },
        ptIdx: { type: Number, default: 0 },
        noWatch: { type: Boolean, default: false }, // 데이터 수집 설정 페이지에서 watch 동작이 안되서 임시 방편(삭제 해야함)
        customHeight: { type: String, detault: "" },
        global: { type: Boolean, default: false },
        pointAddOrDelete: { type: Object, default: null },
    },
    async created() {},
    mounted() {
        if (this.noWatch) this.loadData();
    },
    destroyed() {},
    data() {
        return {
            detailInfo: null,
            mode: "view", // 편집모드 : edit/view
            chartActive: null,
            showTrend: false,
            modalTitle: null,
            rules,
            validMessage,
            popupMessages,
            // deletePtIdx: [],
        };
    },
    computed: {
        // modalTitle() {
        //     return `${this.detailInfo.ptName} (${this.detailInfo.ptIdx})`;
        // },
        isEditMode() {
            return this.mode === "edit" || this.mode === "new";
        },
        isNewMode() {
            return this.mode === "new";
        },
        isViewMode() {
            return this.mode === "view";
        },
        viewTitle() {
            // let mode = '';
            // // if (this.isViewMode) mode = ' 조회';
            // if (this.isViewMode) mode = ` 조회`;
            // else if (this.isNewMode) mode = ' 추가';
            // else if (this.isEditMode) mode = ` 수정`;

            return this.title;
        },
        ptTypeCode() {
            try {
                const result = this.$store.state.commonCodes.ptTypeCode.find(
                    (item) => item.value == this.detailInfo.ptTypeCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        dataSourceType() {
            try {
                const result = this.$store.state.commonCodes.dataSourceType.find(
                    (item) => item.value == this.detailInfo.dataSourceCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        energySourceCode() {
            try {
                const result = this.$store.state.commonCodes.energySourceCode.find(
                    (item) => item.value == this.detailInfo.energySourceCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        unitSmallCode() {
            try {
                const result = this.$store.state.unitCodes[this.detailInfo.unitLargeCode].find(
                    (item) => item.value == this.detailInfo.unitSmallCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        ptVal() {
            const unit = this.unitSmallCode;

            if (unit != "-")
                return this.detailInfo.ptVal ? this.detailInfo.ptVal._comma() + ` ${unit}` : "0" + ` ${unit}`;
            return this.detailInfo.ptVal ? this.detailInfo.ptVal._comma() : "0";
        },
        ptOrg() {
            const unit = this.unitSmallCode;

            if (unit != "-")
                return this.detailInfo.ptOrg ? this.detailInfo.ptOrg._comma() + ` ${unit}` : "0" + ` ${unit}`;
            return this.detailInfo.ptOrg ? this.detailInfo.ptOrg._comma() : "0";
        },
        ptPre() {
            const unit = this.unitSmallCode;

            if (unit != "-")
                return this.detailInfo.ptPre ? this.detailInfo.ptPre._comma() + ` ${unit}` : "0" + ` ${unit}`;
            return this.detailInfo.ptPre ? this.detailInfo.ptPre._comma() : "0";
        },
        isOutOfDate() {
            const now = new Date();
            const strUpdDt = "" + this.detailInfo.updDt;
            const passed = strUpdDt.passedMin(now);

            return passed > 15;
        },
        covStatus() {
            return this.detailInfo.isCov == 1 ? true : false;
        },
        ignoreZeroStatus() {
            return this.detailInfo.isIgnoreZero == 1 ? true : false;
        },
        agrgtnStatus() {
            return this.detailInfo.agrgtnYn == "Y" ? true : false;
        },
        enabledStatus() {
            return this.detailInfo.enabled == "Y" ? true : false;
        },
    },
    watch: {
        pointAddOrDelete(newVal) {
            console.log("pointAddOrDelete in detailPanel", newVal);
            if (!newVal) {
                return;
            } else if (newVal.type === "newData") {
                this.handleIconClick(newVal.type);
            }
        },
        ptIdx(newVal) {
            if (!newVal) {
                // clear point data
                this.clearData();
            } else {
                // load point data
                this.loadData();
            }
        },
    },
    methods: {
        // List-Detail Common Functions
        changeEditMode() {
            this.mode = this.mode === "edit" ? "view" : "edit";
        },

        async notifyCancelEdit() {
            // return true : Edit Cancel & Proceed Next Step
            //        false: Stop.
            if (this.isEditMode) {
                const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
                if (result.value) return true;
                else return false;
            }

            return true;
        },

        // list-Detail Data Functions
        newData() {
            this.mode = "new";
            this.detailInfo = {
                ptAddr: "",
                ptGroup: "",
                ptName: "",
                isVirtual: null,
                purpsUseCode: null,
                dataSourceCode: null,
                dataTypeCode: null,
                ptTypeCode: null,
                valueTypeCode: null,
                energySourceCode: null,
                energySplyTypeCode: null,
                itemUseageCode: null,
                unitLargeCode: null,
                unitSmallCode: null,
                applyZoneIdx: null,
                isCov: 0,
                isIgnoreZero: 0,
                agrgtnYn: "Y",
                enabled: "Y",
            };

            return this.detailInfo;
        },

        async loadData() {
            try {
                let result = await backEndApi.pointInfo.getPointDetailNew(this.ptIdx);

                if (result.status == 200) {
                    result.data.regDate = moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss");
                    result.data.modDate = moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss");
                    this.detailInfo = { ...result.data };
                    this.mode = "view";
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_NO_SEARCH_DATA_POPUP_MESSAGE);
                console.error(e);
            }
        },

        clearData() {
            this.detailInfo = null;
            this.mode = "view";

            this.$forceUpdate();
        },

        async saveData() {
            try {
                let result = null;
                let pointUpdate = null;
                if (this.isNewMode) {
                    result = await backEndApi.pointInfo.insertPointInfo(this.detailInfo);
                } else {
                    result = await backEndApi.pointInfo.updatePointInfo(this.detailInfo);
                    // 관제점 정보를 업데이트한 후에는 새롭게 정보를 가져와서 반영한다.
                    pointUpdate = await backEndApi.pointInfo.getPointDetailNew(this.ptIdx);
                }

                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    if (this.isNewMode) this.$emit("data-updated", "insert", this.detailInfo);
                    else this.$emit("data-updated", "update", this.detailInfo);
                    this.mode = "view";

                    // 관제점 데이터를 업데이트한 후에는 새롭게 정보를 가져와서 반영한다.
                    if (!this.isNewMode && pointUpdate.status == 200) {
                        this.detailInfo = pointUpdate.data;
                    }
                } else if (result.status !== 200) {
                    this.alertWarning(`${result.data.message}`);
                    throw new Error(result.data.message);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async deleteData() {
            const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
            if (!result.value) return;

            const deletedPtIdx = this.detailInfo.ptIdx;

            try {
                const ptIdxs = [deletedPtIdx];
                const result = await backEndApi.pointInfo.deletePointAndFacilityMap(ptIdxs);
                if (result.status == 200) {
                    this.clearData();
                    this.$emit("data-updated", "delete", deletedPtIdx);
                    this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async addClone() {
            this.mode = "new";
            this.detailInfo.ptIdx = null;
            this.detailInfo.ptAddr = this.detailInfo.ptAddr + "-Clone";
            this.detailInfo.ptName = this.detailInfo.ptName + "/복제";
        },

        getData() {
            return this.detailInfo;
        },

        // Private Event Handling Functions
        async handleSave() {
            await this.saveData();
        },

        async handleClose(event) {
            const result = await this.notifyCancelEdit();
            if (!result) {
                event && event.preventDefault();
                return false;
            }
            // this.toggleEditMode();
            this.clearData();
            this.$emit("closed", this.ptIdx);

            return true;
        },
        async handleIconClick(type) {
            if (type == "mode") {
                this.mode = this.isEditMode ? "view" : "edit";
            } else if (type == "trend") {
                this.showTrendModal();
            } else if (type == "newData") {
                await this.newData();
            } else if (type == "addClone") {
                await this.addClone();
            } else if (type == "delete") {
                await this.deleteData();
            } else {
                console.error("Unknown event type. ", type);
            }
        },

        isAggregationEnabled() {
            if (this.agrgtnStatus) this.detailInfo.agrgtnYn = "N";
            else this.detailInfo.agrgtnYn = "Y";
        },

        isCovEnabled() {
            if (this.covStatus) this.detailInfo.isCov = 0;
            else this.detailInfo.isCov = 1;
        },

        isIgnoreZeroEnabled() {
            if (this.ignoreZeroStatus) this.detailInfo.isIgnoreZero = 0;
            else this.detailInfo.isIgnoreZero = 1;
        },

        isInUseEnabled() {
            if (this.enabledStatus) this.detailInfo.enabled = "N";
            else this.detailInfo.enabled = "Y";
        },

        // Sub Modal Functions
        showTrendModal() {
            this.$refs.PointTrendModal.showModal();
        },

        hideTrendModal() {
            this.$refs.PointTrendModal.hideModal();
        },
    },
};
</script>

<style scoped>
.detail-placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    font-size: 12px;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 8px 8px 8px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    background-color: #f1f1f1;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 2px;
    padding: 20px;
    /* overflow: hidden; */
    overflow-y: auto;
    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-status {
    width: 100%;
    padding: 8px 20px 8px 20px;
    margin-bottom: 20px;
    /* flex: 0 0 auto; */

    display: flex;
    justify-content: space-around;
    align-items: center;

    background-color: #eceff1;
    color: #455a64;
    border-radius: 8px;
    box-shadow: inset 0 0 3px #455a6420;

    font-size: 12px;
    text-align: center;
}

.status-title {
    /* font-size: 18px; */
    margin-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: center;
}

.status-value {
    /* font-size: 16px; */
    min-width: 50px;
    font-weight: 800;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: center;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    /* margin-bottom: 8px !important; */
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 4px;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

.out-of-date {
    color: #ff6d00;
}

.title-icon {
    font-size: 1rem !important;
}

.form-group {
    width: 100%;
    margin-bottom: 20px;
}

.row {
    width: 100%;
    margin-bottom: 12px;
}

.input-form {
    width: 100%;
    /* 부모 요소에 꽉 맞춤 */
    box-sizing: border-box;
    /* 입력 폼 요소의 패딩과 보더를 포함하여 크기를 계산 */
}

.input-form input {
    width: 100%;
    /* 입력 요소가 부모 요소에 꽉 맞춤 */
    padding: 10px;
    /* 선택적으로 패딩 설정 */
    margin-bottom: 10px;
    /* 선택적으로 아래쪽 여백 추가 */
}

.detail-btn {
    /* height: 50px; */
    margin: 0px 4px 0px 4px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.title {
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    display: inline-block;
    margin: 2px;
    padding: 0px 10px 0px 10px;

    min-width: 20px;
    white-space: nowrap;
    /* background-color: #777; */

    color: #fff;
    line-height: 22px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;

    text-align: center;
    vertical-align: middle;
}

.badge.ptAddr {
    background-color: #ff6d00;
    color: #fff;
}

.badge.ptIdx {
    background-color: #546e7a;
    color: #fff;
}

.badge.option {
    background-color: #cfd8dc;
    color: #000;
}

.status-icon {
    font-size: 16px;
    color: #546e7a;
    background-color: #eee;
    border: solid #ccc 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #ccc;
}

.status-icon.active {
    color: #fff;
    background-color: #546e7a;
}

.status-alert-icon {
    font-size: 16px;
    color: #ed7d31;
    background-color: #eee;
    border: solid #ccc 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-alert-icon:hover {
    /* color: #546E7A; */
    color: #ed7d31;
    background-color: #ccc;
}

.status-alert-icon.active {
    color: #eceff1;
    background-color: #546e7a;
}

.btn-group .btn {
    padding: 7px 0.1rem;
}
</style>
