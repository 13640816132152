<template>
    <div>
        <b-modal :title="title" centered ref="PointTrend" @hidden="hideModal" dialog-class="custom-modal-width">
            <div class="row">
                <span class="mx-4 font-weight-bold"> {{ pointInfo.ptName }} </span>
                <span class="badge title1"> {{ pointInfo.ptAddr }} </span>
                <span class="badge title2"> {{ pointInfo.ptIdx }} </span>
                <span class="badge options"> {{ ptTypeCode }} </span>
                <span class="badge options"> {{ unitSmallCode }} </span>
                <span class="badge options"> {{ energySourceCode }} </span>
                <span class="badge options"> {{ dataSourceType }} </span>
            </div>
            <transition-group name="fade" tag="div">
                <div class="animated-div" v-for="(chart, index) in charts" :key="generateUniqueId(chart, index)">
                    <div class="chart-title">
                        <button class="status-icon" @click.stop="selectCurrentChart(index)">
                            {{ chart.context.timeDsvn }}
                        </button>
                        <span> {{ chart.context.fromDate }} ~ </span>
                        <span>
                            {{ chart.context.toDate }}
                        </span>
                    </div>

                    <div class="chart-area">
                        <!-- MixChart 컴포넌트 사용 -->
                        <MixChart
                            style="height:100%"
                            @chartClick="handleChartClick"
                            :context="{ index: index, timeDsvn: chart.context.timeDsvn }"
                            :chartData="chart.result.data"
                            :options="chart.result.option"
                            class="chart"
                        />
                    </div>
                </div>
            </transition-group>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-secondary" style="flex: 1;" @click="hideModal">닫기</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import backEndApi from "@src/api/backEndApi";
import { generateColorGradient } from "@views/new/lib/colorGradient";
import MixChart from "@src/components/vue-chartjs/NewMixChart.vue";

export default {
    components: {
        MixChart,
    },
    props: ["title", "pointInfo"],
    created() {},
    async mounted() {
        // 기본 Chart Div 추가
        // const nextContext = this.getNextChartContext();
        // this.addNextChart(0, nextContext);
    },
    beforDestroy() {},
    data() {
        return {
            charts: [],
        };
    },
    computed: {
        ptTypeCode() {
            try {
                const result = this.$store.state.commonCodes.ptTypeCode.find(
                    (item) => item.value == this.pointInfo.ptTypeCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        dataSourceType() {
            try {
                const result = this.$store.state.commonCodes.dataSourceType.find(
                    (item) => item.value == this.pointInfo.dataSourceCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        energySourceCode() {
            try {
                const result = this.$store.state.commonCodes.energySourceCode.find(
                    (item) => item.value == this.pointInfo.energySourceCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
        unitSmallCode() {
            try {
                const result = this.$store.state.unitCodes[this.pointInfo.unitLargeCode].find(
                    (item) => item.value == this.pointInfo.unitSmallCode
                );
                return result.text ?? "-";
            } catch (err) {
                return "-";
            }
        },
    },
    watch: {},
    methods: {
        generateUniqueId(item, index) {
            return `${item.context.ptIdxs}-${index}`;
        },
        async handleChartClick(context, event, data) {
            const index = context.index;
            const timeDsvn = context.timeDsvn;
            const currentContext = { timeDsvn, data };

            const nextContext = this.getNextChartContext(currentContext);
            this.addNextChart(index + 1, nextContext);
        },
        showModal() {
            this.$refs.PointTrend.show();
            const nextContext = this.getNextChartContext();
            this.addNextChart(0, nextContext);
        },
        hideModal() {
            this.$refs.PointTrend.hide();
        },
        getNextChartContext(currentCtx) {
            let nextContext = null;

            if (!currentCtx) {
                const dateFormat = "YYYY-MM";
                const duration = 36; // in Month
                const toDate = moment();
                const fromDate = moment().subtract(duration, "months");

                nextContext = {
                    timeDsvn: "MONTH",
                    fromDate: fromDate.format(dateFormat),
                    toDate: toDate.format(dateFormat),
                };
            } else {
                const dateFormat = "YYYY-MM-DD";
                const selected = moment(currentCtx.data.xValue);

                if (currentCtx.timeDsvn == "MONTH") {
                    nextContext = {
                        timeDsvn: "DAY",
                        fromDate: selected.startOf("month").format(dateFormat),
                        toDate: selected.endOf("month").format(dateFormat),
                    };
                } else if (currentCtx.timeDsvn == "DAY") {
                    const dateFormat = "YYYY-MM-DD HH";
                    nextContext = {
                        timeDsvn: "HOUR",
                        fromDate: selected.startOf("day").format(dateFormat),
                        toDate: selected.endOf("day").format(dateFormat),
                    };
                } else if (currentCtx.timeDsvn == "HOUR") {
                    const dateFormat = "YYYY-MM-DD HH:mm";
                    const duration = 3; // in Hours
                    nextContext = {
                        timeDsvn: "QUARTER",
                        fromDate: selected
                            .clone()
                            .subtract(duration, "hours")
                            .format(dateFormat),
                        toDate: selected
                            .clone()
                            .add(duration, "hours")
                            .format(dateFormat),
                    };
                } else if (currentCtx.timeDsvn == "QUARTER") {
                    const dateFormat = "YYYY-MM-DD HH:mm";
                    const duration = 1; // in Hours
                    nextContext = {
                        timeDsvn: "MIN",
                        fromDate: selected
                            .clone()
                            .subtract(duration, "hours")
                            .format(dateFormat),
                        toDate: selected
                            .clone()
                            .add(duration, "hours")
                            .format(dateFormat),
                    };
                } else {
                    return null;
                }
            }

            return nextContext;
        },
        selectCurrentChart(index) {
            // 현재 인덱스보다 뒤에 있는 모든 div를 제거
            this.charts = this.charts.slice(0, index + 1);
        },
        async addNextChart(index, nextContext) {
            // 제거할 Chart를 결정 (추가할 위치 이후의 Chart는 제거함)
            this.charts = this.charts.slice(0, index + 1);

            const result = await this.getTrendData(nextContext);
            const chartData = this.makeChartData(result, nextContext);

            // 신규 차트의 경우, Charts의 마지막에 추가
            if (index == this.charts.length) {
                const newChart = {
                    context: nextContext,
                    result: chartData[0],
                };
                this.charts.push(newChart);
            } else {
                // index 차트 데이터를 갱신
                this.charts[index].context = nextContext;
                this.charts[index].result = chartData[0];
            }

            // 반응형 데이터 변경
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
        async getTrendData(chartContext) {
            try {
                chartContext.ptIdxs = this.pointInfo.ptIdx;

                let result = await backEndApi.dataAnalysis.fetchSummaryData2(chartContext);
                if (result.status == 200) {
                    return result.data;
                    // result.data.forEach(item => item.updDt = moment(item.updDt).format('YYYY-MM-DD HH:mm:ss'));
                    // this.rltdLiveStatus = result.data;
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (err) {
                console.error(err);
            }
        },
        formatResultDatetime(timeDsvn, isoDate) {
            const date = new Date(isoDate);

            if (timeDsvn == "YEAR") {
                return date.format("yyyy");
            } else if (timeDsvn == "MONTH") {
                return date.format("yyyy-MM");
            } else if (timeDsvn == "DAY") {
                return date.format("MM-dd");
            } else if (timeDsvn == "HOUR") {
                return date.format("MM-dd HH:mm");
            } else {
                return date.format("MM-dd HH:mm");
            }
        },

        formatXAxisLabel() {},

        makeChartData(baseData, nextContext) {
            const charts = [];

            const headers = baseData.headers;
            // const results = baseData.results;

            let results;

            if (nextContext.timeDsvn == "QUARTER") {
                const regs = baseData.results.map((result) => moment(result.regDt).format("YYYY-MM-DD HH:mm"));
                const startIndex = regs.indexOf(nextContext.fromDate);
                const endIndex = regs.indexOf(nextContext.toDate);
                results = baseData.results.slice(startIndex, endIndex + 1);
            } else if (nextContext.timeDsvn == "MIN") {
                const regs = baseData.results.map((result) => moment(result.regDt).format("YYYY-MM-DD HH:mm"));
                const startIndex = regs.indexOf(nextContext.fromDate);
                const endIndex = regs.indexOf(nextContext.toDate);
                results = baseData.results.slice(startIndex, endIndex + 1);
            } else {
                results = baseData.results;
            }

            const startColor = "#000000";
            const endColor = "#0000FF";
            let backgroundColors = [];
            if (headers.length > 1) {
                backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1);
            } else {
                backgroundColors[0] = "#A0A0A0";
            }

            headers.forEach((header, headerIndex) => {
                const backgroundColor = backgroundColors[headerIndex]; // 단일 색상

                const unit = this.$store.state.units.find((unit) => unit.value == header.unitSmallCode)?.text ?? "-";
                const borderColor = backgroundColor + "99";
                const dispText = header.ptName ?? header.ptAddr;

                // 시간축(xAxis) 표시용 설정
                const displayFormats = {
                    millisecond: "MM-DD HH:mm:ss.SSS",
                    second: "MM-DD HH:mm:ss",
                    minute: "MM-DD HH:mm",
                    hour: "MM-DD HH:mm",
                    day: "YYYY-MM-DD",
                    week: "YYYY-MM-DD",
                    month: "YYYY-MM",
                    quarter: "YYYY [Q]Q",
                    year: "YYYY",
                };

                let timeUnit = "";

                if (header.timeDsvn == "MONTH") {
                    timeUnit = "month";
                } else if (header.timeDsvn == "DAY") {
                    timeUnit = "day";
                } else if (header.timeDsvn == "HOUR") {
                    timeUnit = "hour";
                } else {
                    timeUnit = "minute";
                }

                charts.push({
                    key: `${header.ptAddr}`,
                    flag: true,
                    data: {
                        // labels: results.map(result => {
                        //     return this.formatResultDatetime(header.timeDsvn, result.regDt);
                        // }),
                        labels: results.map((result) => result.regDt),
                        datasets: [
                            {
                                key: dispText,
                                propCode: dispText,
                                type: "line",
                                lineTension: 0.5,
                                borderWidth: 1.3,
                                fill: true,
                                label: dispText + ` (${unit})`,
                                pointRadius: 3,
                                showLine: true,
                                interpolate: true,
                                spanGaps: true, // 누락된 데이터 연결 옵션
                                borderColor,
                                backgroundColor: backgroundColor + "44",
                                data: results.map((result) =>
                                    result[header.ptIdx] === undefined ? null : result[header.ptIdx]
                                ),
                            },
                        ],
                    },
                    option: {
                        responsive: true,
                        // onClick: (event, elements) => {

                        //     if (elements.length > 0) {
                        //         const elementIndex = elements[0].index;
                        //         let clickedLabel = results.map(result => result.regDt)[elementIndex];

                        //     }
                        // },
                        maintainAspectRatio: false,
                        animation: {
                            duration: 1000,
                            easing: "easeOutQuart",
                        },
                        legend: {
                            display: true,
                            align: "end",
                            labels: {
                                boxWidth: 12,
                                fontColor: backgroundColor + "FF",
                                fontSize: 10,
                            },
                        },
                        title: {
                            display: false,
                            align: "start",
                            position: "top",
                            text: dispText,
                            fontSize: 12,
                            fontColor: "#000000",
                        },
                        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                        hover: {
                            mode: "index",
                            intersect: true,
                            animationDuration: 400,
                        },
                        elements: {
                            point: {
                                hitRadius: 25, // 포인트 클릭 반경 조절
                            },
                        },
                        tooltips: {
                            mode: "index",
                            intersect: false,
                            titleFontSize: 10,
                            bodyFontSize: 11,
                            enabled: false,
                            custom: function(tooltipModel) {
                                let bodyEmptyCheck = false;

                                var tooltipEl = document.getElementById("chartjs-tooltip");

                                if (!tooltipEl) {
                                    tooltipEl = document.createElement("div");
                                    tooltipEl.id = "chartjs-tooltip";
                                    tooltipEl.innerHTML = "<table></table>";
                                    document.body.appendChild(tooltipEl);
                                }

                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }

                                if (tooltipModel.body) {
                                    let NOTNULLValue = tooltipModel.body.find((v) =>
                                        v.lines.find((t) => !t.includes("null"))
                                    );
                                    if (!NOTNULLValue) {
                                        bodyEmptyCheck = true;
                                    }
                                }

                                if (bodyEmptyCheck) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }

                                // 스타일 설정
                                tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.7)"; // 배경색 설정
                                tooltipEl.style.color = "#fff"; // 글자색 설정
                                tooltipEl.style.paddingTop = "4px"; // 패딩 설정
                                tooltipEl.style.paddingBottom = "4px"; // 패딩 설정
                                tooltipEl.style.paddingLeft = "8px"; // 패딩 설정
                                tooltipEl.style.paddingRight = "8px"; // 패딩 설정
                                tooltipEl.style.borderColor = tooltipModel.borderColor;
                                tooltipEl.style.borderWidth = tooltipModel.borderWidth + "px";
                                tooltipEl.style.borderRadius = tooltipModel.cornerRadius + "px";
                                tooltipEl.style.borderStyle = "solid";
                                tooltipEl.style.position = "absolute";
                                tooltipEl.style.pointerEvents = "none";
                                tooltipEl.style.zIndex = "9999";
                                tooltipEl.style.opacity = 0.8; // 투명도 설정

                                if (tooltipModel.body) {
                                    let titleLines = tooltipModel.title || [];
                                    let bodyLines = tooltipModel.body.map((item) => item.lines);

                                    let innerHtml = "<thead>";

                                    titleLines.forEach(function(title) {
                                        innerHtml += '<tr><th style="color: #FFAB00;">' + title + "</th></tr>"; // x라벨 색상
                                    });
                                    innerHtml += "</thead><tbody>";

                                    bodyLines.forEach(function(body, i) {
                                        let colors = tooltipModel.labelColors[i];
                                        let style = "background:" + colors.backgroundColor;
                                        style += "; border-color:" + colors.borderColor;
                                        style += "; border-width: 2px";
                                        style += "; width: 10px";
                                        style += "; height: 10px";
                                        style += "; font-size: 10px";
                                        style += "; align-self: center";
                                        style += "; margin-right: 2px";
                                        let span = '<div style="' + style + '"></div>';
                                        innerHtml +=
                                            '<tr><td style="display:flex; color: #A7FFEB;">' +
                                            span +
                                            body +
                                            "</td></tr>"; // 값 색상
                                    });
                                    innerHtml += "</tbody>";

                                    let tableRoot = tooltipEl.querySelector("table");
                                    tableRoot.innerHTML = innerHtml;
                                }

                                let position = this._chart.canvas.getBoundingClientRect();
                                let xOffset = 20; // 추가된 x 옵셋
                                tooltipEl.style.left =
                                    position.left + window.pageXOffset + tooltipModel.caretX + xOffset + "px";
                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
                                tooltipEl.style.opacity = 1;
                            },
                            callbacks: {
                                label: (tooltipItems, data) => {
                                    let unit = this.unitSmallCode;
                                    let value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                                    value = value !== undefined ? value.toLocaleString() : "No data"; // 데이터가 없을 경우 처리
                                    // let label = data.datasets[tooltipItems.datasetIndex].label || '';
                                    // return label ? `${label}: <span style="color: #FF00FF;">${value}</span>` : `<span style="color: #FF00FF;">${value}</span>`; // 단위 색상
                                    let style = "color: #A7FFEB";
                                    style += "; margin-right: 4px";
                                    style += "; font-size: 12px";
                                    style += "; font-weight: 700";
                                    style += "; align-self: center";

                                    return unit != "-"
                                        ? `<span style="${style}">${value}</span><span style="color: #EEEEEE;">${unit}</span>`
                                        : `<span style="${style}">${value}</span>`; // 단위 색상
                                },
                            },
                        },
                        scales: {
                            xAxes: [
                                {
                                    id: "x-axis-0",
                                    type: "time",
                                    time: {
                                        unit: timeUnit,
                                        tooltipFormat: displayFormats[timeUnit],
                                        displayFormats: {
                                            [timeUnit]: displayFormats[timeUnit],
                                        },
                                    },
                                    stacked: false,
                                    offset: true,
                                    ticks: {
                                        maxTicksLimit: 10,
                                        autoSkip: true,
                                        fontColor: "#000000",
                                        fontSize: 10,
                                        maxRotation: 0,
                                    },
                                    gridLines: {
                                        display: true,
                                        color: backgroundColor + "44",
                                        borderDash: [2, 1],
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    id: "y-axis-0",
                                    display: true,
                                    position: "left",
                                    stacked: false,
                                    ticks: {
                                        maxTicksLimit: 5,
                                        beginAtZero: true,
                                        fontColor: "#000000",
                                        fontSize: 10,
                                    },
                                    gridLines: {
                                        display: true,
                                        color: backgroundColor + "44",
                                        borderDash: [2, 1],
                                    },
                                },
                            ],
                        },
                        plugins: {
                            zoom: {
                                pan: {
                                    enabled: true,
                                    mode: "xy",
                                },
                                zoom: {
                                    enabled: true,
                                    drag: true,
                                    mode: "xy",
                                },
                            },
                        },
                    },
                });
            });

            return charts;
        },
        // async getTrendData(cond) {
        //     try {
        //         cond.ptIdxs = this.ptIdx;

        //         let result = await backEndApi.dataAnalysis.fetchSummaryData2(cond);
        //         if (result.status == 200) {
        //             return result.data;
        //             // result.data.forEach(item => item.updDt = moment(item.updDt).format('YYYY-MM-DD HH:mm:ss'));
        //             // this.rltdLiveStatus = result.data;
        //         }
        //     } catch (err) {
        //         console.error(err);
        //     }
        // },
    },
};
</script>

<style>
.custom-modal-width {
    max-width: 50vw !important;
    width: 50vw !important;
}
</style>

<style scoped>
.animated-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    /* 차트 영역을 고려한 높이 설정 */
    margin: 10px 0;
    background-color: #f1f1f1;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.chart-title {
    height: 100%;
    width: 200px;
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* transition: flex-grow 0.3s ease; */

    /* background-color: #ececec; */
    background-color: #f1f1f1;

    padding: 8px 16px 8px 16px;
    margin-bottom: 0px !important;
    font-weight: bold;
    /* display: flex; */
    /* margin-top: 10px; */

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
}

.chart-area {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 15px;
    overflow: auto;

    /* border-top: 1px solid #ccc; */
    border-radius: 0px 0px 6px 6px;
    border: solid #ececec 1px;
}

.chart {
    width: 100%;
    height: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    height: 0;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.options {
    background-color: #cfd8dc;
    color: #000;
}

.status-icon {
    font-size: 12px;
    width: 100%;
    color: white;
    background-color: #3c5b6f;
    border: solid #3c5b6f 1px;
    /* background-color: #FF6D00;
    border: solid #FF6D00 1px; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.05rem;
    margin-bottom: 1rem;
    transition: background-color 0.5s;
    /* width: 4rem; */
    height: 1.8rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    color: white;
    background-color: #ff6d00;
}

.status-icon.active {
    color: #eceff1;
    background-color: #546e7a;
}
</style>
