<template>
    <div class="col-md-12 default">
        <div class="col-xl-12">
            <header-box :title="`${$t('관제점 구성 관리')} > ${$t('관제점 관리')}`" />

            <div class="searchArea row">
                <div class="col-md-12 innerBox">
                    <search-box
                        :ref="'search'"
                        @search:data="onSearch"
                        @new="newModal"
                        @download="onExcelDownload"
                        @csvupload="csvUploadModal"
                    />
                </div>
            </div>

            <div class="bg-white">
                <grid-box :ref="'gridBox'" :data="items" @pointLog:ptAddr="pointLogModal" @reSearch="reSearch" />
            </div>

            <b-modal ref="newModal" size="xl" :hide-footer="true" :title="$t('관제점 등록')">
                <new-modal @reSearch="reSearch" @closeModal="closeModal" />
            </b-modal>

            <b-modal ref="pointLogModal" size="xl" :hide-footer="true" :title="$t('관제점 계측 이력 조회')">
                <point-log-modal :ptAddr="ptAddr" />
            </b-modal>
        </div>
    </div>
</template>

<script>
// import backEndApi from "../../../../api/backEndApi";
import SearchBox from "./Search.vue";
// import GridBox from "../../component/gridBox/pointManagement/Grid.vue";
import GridBox from "./Grid.vue";

// import newModal from "./modal/New.vue";
import newModal from "./PointModal.vue";

import PointLogModal from "./modal/PointLog.vue";

import backEndApi from "@src/api/backEndApi";
import HeaderBox from "../../component/headerBox/analysis/Header.vue";

// import * as DataList from './New/DataList';

export default {
    name: "PointMgmt",
    props: [],
    components: {
        SearchBox,
        GridBox,
        newModal,
        PointLogModal,
        HeaderBox,
    },
    watch: {},
    data() {
        return {
            newPointModal: false,
            items: null,
            ptAddr: null,
            publicCode: null,
        };
    },
    async created() {
        console.log("point management pointMgmt");
        this.publicCode = await this.getPublicCode();
    },
    mounted() {},
    methods: {
        async getPublicCode() {
            let result = await backEndApi.codes.getCommonCodeList();
            return result.data.publicCode;
        },
        closeModal() {
            this.$refs.newModal.hide();
        },
        onSearch(data) {
            this.items = data;
        },
        newModal() {
            this.$refs.newModal.show();
        },
        pointLogModal(ptAddr) {
            this.ptAddr = ptAddr;
            this.$refs.pointLogModal.show();
        },
        csvUploadModal() {
            this.$refs.excelUploadModal.show();
        },
        reSearch() {
            this.$refs.search.reSearch();
            this.$refs.newModal.hide();
        },
        onExcelDownload() {
            this.$refs.gridBox.onExcelDownload();
        },
    },
};
</script>

<style scoped></style>
<!-- makeDefaultLayout(){
  // let defaultLayout = {
  //   ptIdx: "",
  // };

  for(let i = 0; i < this.publicCodeList.length; i++){
    console.log
    const largeCodeName = this.publicCode.filter(data => data.largeCode === this.publicCodeList[i])
    console.log(largeCodeName);
    // defaultLayout[largeCodeName] = `${largeCodeName}1` 
  }
  
  // this.default = [defaultLayout];
  // console.log(this.default);
}, -->
