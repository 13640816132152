<template>
    <div v-xe-pm.E.A>
        <!-- <title-icon :title="$t('장비설정 관리')" icon="fa-plug" /> -->
        <header-box :title="`${$t('관제점 구성 관리')} > ${$t('데이터 수집 설정')}`" />

        <div class="row">
            <div class="col-md-3">
                <panel
                    :title="$t('장비 목록')"
                    :variant="panelVariant"
                    bodyClass="p-0"
                    :isHoverButton="false"
                    :fixedHeight="720"
                    :class="{ 'panel-loading': reload }"
                    showEditing="false"
                    hideExpand="true"
                    hideReload="true"
                    hideCollapse="true"
                    hideRemove="true"
                    @panel-reload="initSetting"
                >
                    <template slot="button">
                        <button
                            v-if="curNodeType == ''"
                            class="btn btn-xs btn-primary m-r-5"
                            @click="openNewServerModal()"
                        >
                            <i class="fa fa-plus"></i> {{ $t("데이터 수집기 추가") }}
                        </button>
                        <button
                            v-else-if="curNodeType == 'server'"
                            class="btn btn-xs btn-primary m-r-5"
                            @click="openNewChannelModal()"
                        >
                            <i class="fa fa-plus"></i> {{ $t("통신채널 추가") }}
                        </button>
                        <button
                            v-else-if="curNodeType == 'channel'"
                            class="btn btn-xs btn-primary m-r-5"
                            @click="openNewFacilityModal()"
                        >
                            <i class="fa fa-plus"></i> {{ $t("계측장치 추가") }}
                        </button>
                        <button
                            v-else-if="curNodeType == 'facility'"
                            class="btn btn-xs btn-primary m-r-5"
                            @click="openNewSubFacilityModal()"
                        >
                            <i class="fa fa-plus"></i> {{ $t("서브모듈 추가") }}
                        </button>
                        <button class="btn btn-xs btn-purple  m-r-5" @click="toggleExpand()">
                            <i class="fa" :class="isExpand ? 'fa-angle-double-right' : 'fa-angle-double-down'"></i>
                        </button>
                        <button class="btn btn-xs btn-green m-r-5" @click="initSetting()">
                            <i class="fa fa-redo"></i>
                        </button>
                    </template>
                    <liquor-tree
                        ref="tree"
                        v-if="Array.isArray(treeData) && treeData.length > 0 && isShow"
                        :data="treeData"
                        :options="treeOptions"
                        @node:dragging:finish="dragFinish"
                        @node:selected="onNodeSelected"
                    >
                        <template slot-scope="{ node }">
                            <div class="row width-full xe-hover-box">
                                <div class="col-lg-11">
                                    <template v-if="node.data.type == 'server'">
                                        <i class="fas" :class="[node.data.icon]"></i>
                                        {{ node.text }}

                                        <!-- TODO: serverStatus 정상화하고 동작중 표현방법 다시 생각해 봐야 함.
                    <i class="fas fa-plug m-l-5" v-if="node.data.serverStatus && node.data.serverStatus.keepAlive && node.data.serverStatus.keepAlive.passedMin() < 20" v-b-tooltip :title="$t('동작중')"></i> -->
                                    </template>
                                    <span
                                        v-else-if="node.data.type == 'channel'"
                                        :class="[node.data.enable ? '' : 'text-black-transparent-5']"
                                    >
                                        <i
                                            class="fas"
                                            :class="[
                                                node.data.icon,
                                                node.data.keepAlive && node.data.keepAlive.passedMin() < 20
                                                    ? 'fa-spin'
                                                    : '',
                                            ]"
                                        ></i>
                                        {{ node.text }}
                                        <b-badge variant="secondary" class="xe-hover-button m-l-5">
                                            {{ node.data.channelType }}
                                        </b-badge>
                                    </span>
                                    <span
                                        v-else-if="node.data.type == 'facility' || node.data.type == 'subFacility'"
                                        :class="[node.data.isUse ? '' : 'text-black-transparent-5']"
                                    >
                                        <i class="fas" :class="[node.data.icon]"></i>
                                        {{ node.text }}
                                        <b-badge
                                            v-if="node.data.type == 'facility'"
                                            variant="secondary"
                                            class="xe-hover-button m-l-5"
                                        >
                                            {{ node.data.driverType }}
                                        </b-badge>
                                        <b-badge
                                            v-if="node.data.type == 'subFacility'"
                                            variant="secondary"
                                            class="xe-hover-button m-l-5"
                                        >
                                            CT {{ node.data.ct }}
                                        </b-badge>
                                    </span>
                                    <template v-else-if="node.data.type == ''">
                                        <i class="fas" :class="node.data.icon"></i>
                                        {{ node.text }}
                                    </template>
                                </div>

                                <div
                                    class="col-lg-1 m-auto"
                                    style="font-size: 16px"
                                    v-if="node.data.type && node.states.selected"
                                >
                                    <div class="xe-hover-button m-auto">
                                        <i
                                            class="fa fa-edit pull-right"
                                            @click.stop="openNodeModal(node, $event)"
                                            v-b-tooltip.hover
                                            :title="$t('수정')"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </liquor-tree>

                    <div class="panel-loader" v-if="reload"><span class="spinner-small"></span></div>
                </panel>
            </div>
            <div class="col-md-9">
                <facility-map :option="mapOption" :parentData="selectedNode" />
            </div>
        </div>

        <b-modal
            ref="serverModal"
            :hide-footer="true"
            :title="popupServer.serverIdx ? $t(`데이터 수집기 설정`) : $t('데이터 수집기 추가')"
        >
            <server-popup :server="popupServer" :childrens="popupServer.childrens" @save-done="onServerSaveDone" />
        </b-modal>

        <b-modal
            ref="channelModal"
            :hide-footer="true"
            :title="popupChannel.channelIdx ? $t(`통신채널 설정`) : $t('통신채널 추가')"
        >
            <channel-popup
                :serverIdx="popupChannel.serverIdx"
                :channelIdx="popupChannel.channelIdx"
                :childrens="popupChannel.childrens"
                @save-done="onChannelSaveDone"
            />
        </b-modal>

        <b-modal
            ref="facilityModal"
            :hide-footer="true"
            :title="popupFacility.facilityIdx ? $t(`계측장치 설정`) : $t('계측장치 추가')"
            size="md"
        >
            <facility-popup
                :serverIdx="popupFacility.serverIdx"
                :channelIdx="popupFacility.channelIdx"
                :facilityIdx="popupFacility.facilityIdx"
                :channelType="popupFacility.channelType"
                :childrens="popupFacility.childrens"
                @save-done="onFacilitySaveDone"
            />
        </b-modal>

        <b-modal
            ref="subFacilityModal"
            :hide-footer="true"
            :title="popupSubFacility.facilityIdx ? $t(`서브모듈 설정`) : $t('서브모듈 추가')"
            size="md"
        >
            <facility-popup
                :serverIdx="popupSubFacility.serverIdx"
                :channelIdx="popupSubFacility.channelIdx"
                :parentFacilityIdx="popupSubFacility.parentFacilityIdx"
                :facilityIdx="popupSubFacility.facilityIdx"
                :channelType="popupSubFacility.channelType"
                :newCT="popupSubFacility.ct"
                @save-done="onSubFacilitySaveDone"
            />
        </b-modal>
    </div>
</template>

<style scoped>
/* 신규추가 start*/
.panel {
    background-color: white !important;
    overflow: auto;
}
/* 신규추가 end*/

.tree-node.matched > .tree-content {
    background: #f7f2e7;
}

.tree-children {
    transition: unset;
}
</style>

<script>
import LiquorTree from "liquor-tree";
import backEndApi from "@api/backEndApi.js";
import ServerPopup from "./components/ServerPopup.vue";
import ChannelPopup from "./components/ChannelPopup.vue";
import FacilityPopup from "./components/FacilityPopup.vue";
import FacilityMap from "./components/FacilityMap.vue";
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";

// import HeaderBox from "../light/component/headerBox/analysis/Header.vue"

// TODO: 루트 선택시 서버 추가 버튼이 활성화 되지 않음..

// TODO: 관제점 맵핑  사용중 필터 클릭하면, 그리드 내용이 이상해짐.
// TODO: 관제점 맵핑  하위장비 클릭시 - 속성찾기 문자열로 필터링하는 기능 변경해야 함.
// TODO: Use Only 체크박스 모양 바꿔야 함...

// TODO: 에이전트 할당, 에이전트 재시작

export default {
    components: {
        [LiquorTree.name]: LiquorTree,
        ServerPopup,
        ChannelPopup,
        FacilityPopup,
        FacilityMap,
        HeaderBox,
    },
    data() {
        return {
            treeOptions: {
                dnd: false,
            },
            selectedNode: null,
            isShow: true,

            servers: [],
            channels: [],
            facilities: [],
            treeNodes: [],

            curNodeType: "",
            curNodeData: {},
            curChildrens: 0, // 자식 노드 갯수
            curNodeParent: {},

            mapOption: {}, // 관제점맵핑에서 사용

            popupServer: {},
            popupChannel: {},
            popupFacility: {},
            popupSubFacility: {},

            originTreeData: [],

            isExpand: true,
            reload: false,
        };
    },
    computed: {
        treeData() {
            return JSON.parse(JSON.stringify(this.treeNodes));
        },
    },
    watch: {
        treeData: function() {
            this.isShow = false;
            this.$nextTick(() => {
                this.isShow = true;
            });
        },
    },
    mounted() {
        this.initSetting();
    },
    methods: {
        async initSetting() {
            if (this.$refs.tree) {
                this.originTreeData = JSON.parse(JSON.stringify(this.$refs.tree.tree.model[0].children));
            }

            this.reload = true;

            this.isExpand = true;

            let nodes = await backEndApi.facility.searchFacilityTree().then(({ data }) => {
                if (this.$err(data)) return;

                return data;
            });

            this.originExpanded(nodes, this.originTreeData);

            // console.log(nodes);

            this.treeNodes.range([
                {
                    text: this.$t(`수집 네트워크`),
                    state: { expanded: true },
                    data: { type: "", icon: "fa fa-server" },
                    children: nodes,
                },
            ]);

            setTimeout(
                function() {
                    this.reload = false;
                    this.reSelectNodeData();
                }.bind(this),
                200
            );
        },
        reSelectNodeData() {
            if (!this.selectedNode) return;

            const newModel = this.$refs.tree.model;

            const reloadData = this.findNode(this.selectedNode, newModel);
            console.log("---- set selectData Reloaded-----");
            this.selectedNode = reloadData;
        },
        findNode(selectNode, childNodes) {
            let findData = null;

            for (let i = 0; i < childNodes.length; i++) {
                if (childNodes[i].data.type === "facility" || childNodes[i].data.type === "subFacility") {
                    if (
                        childNodes[i].data.type === selectNode.data.type &&
                        childNodes[i].data.text === selectNode.data.text &&
                        childNodes[i].data.facilityIdx === selectNode.data.facilityIdx
                    ) {
                        findData = childNodes[i];
                        break;
                    }
                }
            }

            if (findData === null) {
                for (let i = 0; i < childNodes.length; i++) {
                    if (!childNodes[i].children) continue;
                    if (childNodes[i].children.length == 0) continue;

                    const result = this.findNode(selectNode, childNodes[i].children);
                    return result;
                }
            } else {
                return findData;
            }
        },
        originExpanded(newTreeData, originData) {
            for (let i = 0; i < originData.length; i++) {
                let child = originData[i].children;

                if (newTreeData[i] && originData[i].state && originData[i].state.expanded === true) {
                    newTreeData[i].state = originData[i].state;
                }

                if (child && child.length > 0) {
                    this.originExpanded(newTreeData[i].children, child);
                }
            }
        },
        toggleExpand() {
            this.isExpand = !this.isExpand;
            this.isExpand ? this.$refs.tree.tree.collapseAll() : this.$refs.tree.tree.expandAll();
        },
        dragFinish(node, destinationNode) {
            console.log("dragFinish. node, destinationNode", node, destinationNode);
        },
        onNodeSelected(node) {
            // console.log(node)
            if (node.children) node.states.expanded = !node.states.expanded;

            this.selectedNode = node;

            this.curNodeType = "";
            this.mapOption = {};

            if (node.data.type == "") return;

            this.curNodeType = node.data.type;
            this.curNodeData = node.data;
            this.curChildrens = node.children.length;
            this.curNodeParent = node.parent.data;

            if (node.data.type == "facility") {
                this.mapOption = {
                    server: node.parent.parent.data,
                    channel: node.parent.data,
                    parent: null,
                    facility: node.data,
                };
            } else if (node.data.type == "subFacility") {
                this.mapOption = {
                    server: node.parent.parent.parent.data,
                    channel: node.parent.parent.data,
                    parent: node.parent.data,
                    facility: node.data,
                };
            }
        },
        //#region 모달

        openNodeModal(node) {
            let { data } = node;

            switch (data.type) {
                case "server":
                    this.openServerModal(data);
                    break;
                case "channel":
                    this.openChannelModal(data);
                    break;
                case "facility":
                    this.openFacilityModal(data);
                    break;
                case "subFacility":
                    this.openSubFacilityModal(data);
                    break;
            }
        },
        openNewServerModal() {
            this.popupServer = {};
            this.$refs.serverModal.show();
        },
        openServerModal(server) {
            this.popupServer = server;
            this.popupServer.childrens = this.curChildrens;
            this.$refs.serverModal.show();
        },
        onServerSaveDone() {
            this.$refs.serverModal.hide();
            this.popupServer = {};
            this.initSetting();
        },

        openNewChannelModal() {
            this.popupChannel = { serverIdx: this.curNodeData.serverIdx };
            this.$refs.channelModal.show();
        },
        openChannelModal(channel) {
            this.popupChannel = channel;
            this.popupChannel.childrens = this.curChildrens;
            this.$refs.channelModal.show();
        },
        onChannelSaveDone() {
            this.$refs.channelModal.hide();
            this.popupChannel = {};

            this.initSetting();
        },

        openNewFacilityModal() {
            this.popupFacility = {
                serverIdx: this.curNodeData.serverIdx,
                channelIdx: this.curNodeData.channelIdx,
                channelType: this.curNodeData.channelType,
            };
            this.$refs.facilityModal.show();
        },

        openFacilityModal(facility) {
            this.popupFacility = facility;
            this.popupFacility.channelType = this.curNodeParent.channelType;
            this.popupFacility.childrens = this.curChildrens;
            this.$refs.facilityModal.show();
        },

        onFacilitySaveDone() {
            this.$refs.facilityModal.hide();
            this.popupFacility = {};
            this.initSetting();
        },

        openNewSubFacilityModal() {
            /**
             * NOTE - 선택된 노트에 존재하는 마지막 ct 값을 찾아 +1
             */
            let selectedNode = this.selectedNode;
            console.log(selectedNode);
            // let newCT = -1;
            // if (selectedNode.children.length > 0) {
            //   newCT = 1;
            // } else {
            //    //NOTE - ct 값의 크기에 따라 정렬
            //   selectedNode.children.sort((a, b) => {
            //     if (Number(a.data.ct) > Number(b.data.ct)) {
            //       return 1;
            //     }

            //     if (Number(a.data.ct) < Number(b.data.ct)) {
            //       return -1;
            //     }

            //     return 0;
            //   });

            //   newCT = Number(selectedNode.children[selectedNode.children.length - 1].data.ct) + 1;
            // }

            // Arrange by badblock
            let newCT = 1; // Default Value
            if (selectedNode.children.length > 0) {
                selectedNode.children.sort((a, b) => Number(a.data.ct) - Number(b.data.ct));
                newCT = Number(selectedNode.children[selectedNode.children.length - 1].data.ct) + 1;
            }

            this.popupSubFacility = {
                serverIdx: this.curNodeData.serverIdx,
                channelIdx: this.curNodeData.channelIdx,
                parentFacilityIdx: this.curNodeData.facilityIdx,
                channelType: this.curNodeParent.channelType,
                deviceId: this.curNodeParent.deviceId,
            };

            this.popupSubFacility["ct"] = newCT;
            this.$refs.subFacilityModal.show();
        },

        openSubFacilityModal(facility) {
            this.popupSubFacility = facility;
            this.popupSubFacility.channelType = this.curNodeParent.channelType;
            this.$refs.subFacilityModal.show();
        },

        async onSubFacilitySaveDone() {
            this.$refs.subFacilityModal.hide();
            this.popupSubFacility = {};
            this.initSetting();
        },

        //#endregion
    },
};
</script>
