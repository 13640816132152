<template>
    <div>
        <!-- UPL 제목 -->
        <vvo v-slot="v">
            <div class="row">
                <div class="col-lg-6">
                    <h5>
                        <!-- <i class="fa fas fa-calculator"></i>  -->
                        {{ changeWorkflow ? changeWorkflow.name : "" }}
                        <span class="text-black-transparent-7" v-if="changeWorkflow.name !== parentUpl.name">
                            {{ parentUpl && parentUpl.name ? " - " + parentUpl.name : "" }}
                        </span>
                    </h5>
                </div>
                <div class="col-lg-6">
                    <div class="form-inline m-t-2 m-b-10 pull-right">
                        <button
                            class="btn btn-sm btn-primary pull-right m-r-5"
                            @click="$refs.uplModal.show()"
                            v-b-tooltip.hover
                            :title="$t('계산식 추가')"
                        >
                            <i class="fa fa-save"></i><trans>추가</trans>
                        </button>
                        <button
                            class="btn btn-sm btn-default pull-right m-r-5"
                            @click="onValidate(v, onSaveWorkflow)"
                            v-b-tooltip.hover
                            :title="$t('워크플로우 저장')"
                        >
                            <i class="fa fa-save"></i><trans>저장</trans>
                        </button>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-3 m-t-auto m-b-auto">
                            <span class="m-auto"> {{ $t("데이터 표시") }}: </span>
                        </div>
                        <div class="col-4">
                            <select class="form-control" v-model="selectViewGroup">
                                <!-- <option value="ALL">{{ $t("전체") }}</option> -->
                                <option value="workflow">{{ $t("계산식") }}</option>
                                <option value="group">{{ $t("그룹") }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row form-group justify-content-end">
				<div class="col-3">
				
				</div>
			</div> -->
            <div class="row form-group" v-if="changeUpl.uplType == 'Group' || changeUpl.uplType == 'Point'">
                <!-- 계산식 상세 -->
                <div class="col-lg-4">
                    <div class="row form-group">
                        <div class="col-lg-12">
                            <label> {{ $t(uplTypeName) }} </label>
                            <valid-input
                                :vid="'계산식명'"
                                :inputType="'text'"
                                :inputValue.sync="changeUpl.name"
                                :placeholder="$t('계산식명을 입력하세요.')"
                                :rules="rules.POINT_FORMULA_NAME_RULE"
                                :errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
                                :readonly="isEmpty(changeUpl.uplType)"
                            ></valid-input>
                        </div>
                    </div>
                    <div class="row form-group" v-if="changeUpl.uplType == 'Point'">
                        <div class="col-lg-12">
                            <label class="m-r-5">{{ $t("관제점 주소") }}</label>
                            <pt-select :pt="changeUpl" />
                        </div>
                    </div>
                    <div class="row form-group m-t-1" v-if="changeUpl.uplType == 'Point'">
                        <div class="col-lg-4">
                            <label> <trans>관제점 유형</trans> </label>
                            <input type="text" class="form-control" :value="pt.ptType" readonly />
                        </div>
                        <div class="col-lg-4">
                            <label> <trans>단위</trans> </label>
                            <input type="text" class="form-control" :value="pt.unit" readonly />
                        </div>
                        <div class="col-lg-4">
                            <label> <trans>원본단위</trans> </label>
                            <input type="text" class="form-control" :value="pt.rawUnit" readonly />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="row m-b-5">
                        <div class="col-lg-8">
                            <span>{{ $t("추천 계산식") }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <xe-codemirror
                                v-model="recommendFormula"
                                :hintList="hintLists"
                                arrName="PZ"
                                existExplanation="true"
                            ></xe-codemirror>
                        </div>
                    </div>
                </div>

                <!-- 하단 차트 -->
                <div class="col-lg-4 col-xs-12">
                    <div class="row m-b-5">
                        <div class="col-lg-8">
                            <a
                                v-for="(tab, i) in tabs"
                                :key="i"
                                href="javascript:;"
                                class="xe-tab-underline"
                                @click="toggleTabs(tab)"
                                :class="{ 'f-w-700': tab.active }"
                            >
                                {{ tab.active ? "●" : "○" }}
                                {{ $t(tab.name) }}
                            </a>
                        </div>
                        <div class="col-4" v-if="missingPoint.length > 0">
                            <div style="float: right">
                                <b-badge variant="red" v-b-tooltip.hover :title="missingPoint.join('\r\n,')">
                                    {{ missingPoint.length }}
                                </b-badge>
                            </div>
                            <div style="float: right" class="m-r-5">
                                <span> <trans>존재하지 않는 관제점</trans> </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <xe-codemirror
                                v-model="changeUpl.logic"
                                v-if="activeTab.id == 'logic'"
                                :hintList="hintLists"
                                arrName="PZ"
                                existExplanation="true"
                            >
                            </xe-codemirror>
                            <xe-codemirror
                                v-model="tr.plan"
                                v-if="activeTab.id == 'plan' && isPlanReady"
                                :hintList="hintLists"
                                arrName="PZ"
                                existExplanation="true"
                            >
                            </xe-codemirror>
                            <xe-codemirror
                                v-model="tr.calc"
                                v-if="activeTab.id == 'calc' && isCalcReady"
                                :hintList="hintLists"
                                arrName="PZ"
                                existExplanation="true"
                            >
                            </xe-codemirror>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 워크플로우 상세 -->
            <!-- TODO:  changeWorkflow.workflow.name 의 이름은  changeWorkflow.name 과 서로 다른것인데.. 두개를 동일하게 사용하고 있음..     워크플로우명은 changeWorkflow.name 이 맞음..  -->
            <div class="row form-group" v-else>
                <div class="col-lg-6">
                    <div class="row form-group">
                        <div class="col-lg-12">
                            <label> {{ $t("워크플로우명") }} </label>
                            <valid-input
                                :vid="'워크플로우명'"
                                :inputType="'text'"
                                :placeHolder="$t('워크플로우명을 입력하세요.')"
                                :inputValue.sync="changeWorkflow.name"
                                :rules="rules.POINT_FORMULA_WORKFLOW_NAME_RULE"
                                :errorMessage="popupMessages.POINT_CALCULATION_WORKFLOW_NAME_VALID_POPUP_MESSAGE"
                            >
                            </valid-input>
                            <!-- <input type="text" class="form-control" :placeholder="$t('워크플로우명을 입력하세요.')" v-model="changeWorkflow.name" /> -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-12">
                            <label class="m-r-5"> <trans>설명</trans> </label>
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('설명을 입력하세요.')"
                                v-model="changeWorkflow.comment"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <b-modal ref="uplModal" :title="$t('계산식')" :hide-footer="true">
                <upl-detail @save-done="onUplSaveDone" />
            </b-modal>
        </vvo>
    </div>
</template>

<style scoped>
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background: #fafafa;
    opacity: 0.9;
}
</style>

<script>
import backEndApi from "@api/backEndApi.js";
import UplDetail from "../components/xeUplDetail.vue";
import PtSelect from "@src/views/sys/data-management/point/components/PtSelect.vue";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import { mapState } from "vuex";
import xelib from "xelib";
import xeCodemirror from "./xeCodemirror";

export default {
    props: ["currentWorkflow", "parentUpl", "currentUpl", "calcPointList"],
    components: {
        PtSelect,
        UplDetail,
        xeCodemirror,
    },
    data() {
        return {
            tabs: [
                { id: "logic", name: global.xe.$t("로직"), active: true },
                { id: "plan", name: global.xe.$t("계획"), active: false },
                { id: "calc", name: global.xe.$t("연산"), active: false },
            ],

            propRegular: /\[([\S])*\]/gm,
            // eslint-disable-next-line no-useless-escape
            pointRegular: /[\[\]]/gm,

            // 없는 관제점 확인
            missingPoint: [],

            recommendFormula: "",
            selectViewGroup: "workflow",
            liveAM: this.readStorage("liveAM"),
            popupMessages,
            rules,
            validMessage,

            /**자동완성 리스트 */
            hintLists: [],
        };
    },
    computed: {
        //푸시테스트
        /**codemirror에서 사용할 물리관제점 리스트 */

        logicValue() {
            return this.changeUpl.logic || "";
        },
        ...mapState({
            pointList: (state) => state.pointList,
        }),
        /**
         * 데이터가 준비되었을때 렌더링하기위함
         * @returns {boolean}
         */
        // isLogicReady() {
        //   return this.changeUpl && this.changeUpl.logic !== undefined;
        // },
        isPlanReady() {
            return this.tr && this.tr.plan !== undefined;
        },
        isCalcReady() {
            return this.tr && this.tr.calc !== undefined;
        },
        workflowGroups() {
            if (
                this.currentWorkflow &&
                this.currentWorkflow.workflow &&
                Array.isArray(this.currentWorkflow.workflow.children)
            )
                return this.currentWorkflow.workflow.children;
            else return [];
        },
        activeTab() {
            return this.tabs.find((v) => v.active);
        },
        changeWorkflow() {
            if (this.currentWorkflow) return JSON.parse(JSON.stringify(this.currentWorkflow));
            return {};
        },
        changeUpl() {
            let obj = {};

            if (
                this.changeWorkflow &&
                this.changeWorkflow.workflow &&
                Array.isArray(this.changeWorkflow.workflow.children) &&
                this.changeWorkflow.workflow.children.length > 0
            ) {
                this.changeWorkflow.workflow.children.forTree((upl) => {
                    if (upl.name == this.currentUpl.name) {
                        obj = upl;
                    }
                });
            }

            this.setRecommend(obj.ptAddr);

            return obj;
        },
        isVariable() {
            return this.currentUpl.uplType == "Var" || this.currentUpl.uplType == "Point";
        },
        uplTypeName() {
            switch (this.changeUpl.uplType) {
                case "Group":
                    return "그룹명";
                case "Point":
                    return "계산식명";
                case "Var":
                    return "변수명";
                default:
                    return "";
            }
        },
        pt() {
            return this.liveAM[this.changeUpl.ptAddr] || {};
        },
        tr() {
            return this.changeUpl.tr ? this.changeUpl.tr : { plan: "", calc: "" };
        },
    },
    async created() {
        // this.recommendFormula = await backEndApi.
        this.totalhintIncludeUPLfunc();
    },
    mounted() {
        this.initSetting();
        // this.attachHoverEvent();
    },
    watch: {
        changeUpl() {},
    },
    methods: {
        // attachHoverEvent() {
        //     // ref를 통해 CodeMirror 인스턴스에 접근 (xe-codemirror 컴포넌트에서 editor 프로퍼티 제공)
        //     const cmInstance = this.$refs.logicEditor.editor;
        //     if (cmInstance && cmInstance.getWrapperElement) {
        //         const wrapper = cmInstance.getWrapperElement();
        //         wrapper.addEventListener("mousemove", (e) => {
        //             // 현재 마우스 좌표를 기반으로 에디터 내의 커서 위치 계산
        //             const pos = cmInstance.coordsChar({ left: e.clientX, top: e.clientY });
        //             const token = cmInstance.getTokenAt(pos);
        //             // 토큰이 대괄호로 감싸여 있는지 체크 (예: "[ABC]" 형식)
        //             if (token && token.string && token.string.match(/^\[.+\]$/)) {
        //                 this.handleBracketHover(token, pos);
        //             }
        //         });
        //     }
        // },
        // handleBracketHover(token, pos) {
        //     // 여기에 원하는 동작을 구현합니다.
        //     // 예를 들어, 토큰 내용으로 툴팁을 표시하거나 콘솔에 출력할 수 있습니다.
        //     console.log("대괄호 내부 토큰에 hover:", token.string, "위치:", pos);
        //     // 추가로, 토큰 위치 정보를 이용해 커스텀 툴팁을 띄울 수도 있습니다.
        // },

        totalhintIncludeUPLfunc() {
            if (this.pointList) {
                /**@type {string[]} 관제점배열 순회 => 자동완성에서 보여줄 내용 배열 formatting */
                const hintListFormatting = this.pointList.map((item) => {
                    return `${item.ptAddr} (${item.ptName})`;
                });

                /** @type {string[]} xelib UPL 지원 함수들에서 함수 이름만 추출하여 배열로 만듭니다. */
                const xelibArr = Object.keys(xelib.UplSupportFunctions);

                /** @type {string[]} UPL 전용 자동완성을 위해 각 함수 이름 앞에 'UPL.'을 추가합니다. */
                const xeliArrAddUpl = xelibArr?.map((item) => {
                    return "UPL." + item;
                });

                // xeUpl에서 사용할 최종적인 관제점 리스트
                this.hintLists = [...hintListFormatting, ...xeliArrAddUpl];
            }
        },
        setRecommend(ptAddr) {
            if (!ptAddr) return;

            backEndApi.workflow.recommenWorkflow(ptAddr).then(({ data }) => {
                this.recommendFormula = data;
            });
        },
        initSetting() {
            this.$store.dispatch("UPDATE_PT_MAP");

            this.liveAM = this.readStorage("liveAM");
        },
        toggleTabs(curTab) {
            this.tabs.map((tab) => {
                tab.active = tab == curTab;
            });
        },
        // 정규식으로 계산식에서 사용중인 포인트의 실존 유무를 체크한다.
        regularCheckWithPoint() {
            this.missingPoint.clear();

            let logic = this.changeUpl.logic;
            if (this.isEmpty(logic)) return;

            let missingPoint = [];

            let usePoints = [];

            let matchObj = logic.match(this.propRegular);
            if (Array.isArray(matchObj)) {
                usePoints = matchObj.map((v) => {
                    return v.replace(this.pointRegular, "");
                });
            }

            if (Array.isArray(usePoints) && usePoints.length > 0) {
                usePoints = usePoints.unique(); // 중복 제거

                usePoints.map((point) => {
                    // DB에 저장된 Point에 계산식에 사용한 포인트가 포함되지 않으면 화면에 표시한다.
                    if (!this.liveAM.hasOwnProperty(point)) missingPoint.push(point);

                    // UPL Name도 조회해야함
                    this.currentWorkflow.workflow.children.forTree((node) => {
                        if (node.uplType != "Group" && node.name == point) {
                            const idx = missingPoint.indexOf(point);
                            if (idx > -1) missingPoint.splice(idx, 1);
                        }
                    });
                });
            }

            this.missingPoint.range(missingPoint);
        },
        // Workflow 저장
        onSaveWorkflow() {
            this.alertQuestion(popupMessages.POINT_CALCULATION_WORKFLOW_ADD_POPUP_MESSAGE).then((result) => {
                console.log("onSaveWorkflow result", result);
                if (!result.value) return;

                backEndApi.workflow.saveWorkflow(this.changeWorkflow).then(({ data }) => {
                    if (this.$err(data)) return;

                    this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    this.$emit("save-done");
                });
            });
        },
        onUplSaveDone(newUpl) {
            this.$refs.uplModal.hide();
            this.$emit("insert-upl", newUpl);
            backEndApi.workflow.saveWorkflow(this.changeWorkflow).then(({ data }) => {
                if (this.$err(data)) return;

                this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                this.$emit("save-done");
            });
        },
    },
};
</script>
