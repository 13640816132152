<template>
    <div>
        <div
            id="header"
            class="header navbar-default"
            v-bind:style="pageOptions.pageMobileSidebarToggled ? { marginLeft: '220px' } : {}"
        >
            <div class="navbar-header">
                <!-- 모바일에서는 RightSideBar가 나타나지 않도록 한다. -->
                <button
                    class="navbar-toggle pull-right"
                    v-on:click="toggleMobileRightSidebar"
                    v-if="pageOptions.pageWithTwoSidebar && pageOptions.pageMobileRightSidebarToggled"
                >
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <button
                    class="navbar-toggle pull-left"
                    v-on:click="toggleMobileSidebar"
                    v-if="pageOptions.pageWithTwoSidebar"
                >
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <router-link to="/" class="navbar-brand">
                    <!-- <img v-bind:src="masterLogo" style="width:27px;height:20px;margin-right:7px;" /> -->
                    <!-- <span class="navbar-logo"></span> -->
                    <!-- <b>{{ master.brand }}</b> -->
                    <img :src="logo" />
                </router-link>
                <button
                    class="navbar-toggle"
                    v-on:click="toggleMobileSidebar"
                    v-if="
                        !pageOptions.pageWithTwoSidebar &&
                            !pageOptions.pageWithTopMenu &&
                            !pageOptions.pageWithoutSidebar
                    "
                >
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <button
                    class="navbar-toggle"
                    v-on:click="toggleMobileTopMenu"
                    v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar"
                >
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <!-- 원래는 메가메뉴가 모바일에서 아래 버튼으로 나타나게 되어 있는데, 메가메뉴를 1단계 메뉴로 사용하므로, 현재는 모바일에서 사용하지 않도록 함.  현재 둘다 안나옴
        <button class="navbar-toggle p-0 m-r-0" v-on:click="toggleMobileMegaMenu" v-if="pageOptions.pageWithMegaMenu" >
          <span class="fa-stack fa-lg text-inverse m-t-2">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button> -->
            </div>

            <!-- 메가 메뉴 -->
            <header-mega-menu v-if="pageOptions.pageWithMegaMenu && pageOptions.pageDualMenu"></header-mega-menu>

            <!-- 헤더 버튼 -->
            <ul class="navbar-nav navbar-right">
                <!-- 조회 -->
                <li
                    class="navbar-form"
                    v-if="pageOptions.pageWithSearchBar"
                    v-bind:class="{ 'hidden-sm': pageOptions.pageWithMegaMenu }"
                >
                    <form name="search_form" v-on:submit="searchForm">
                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('키워드를 입력하세요.')"
                                v-model="searchText"
                            />
                            <button type="submit" class="btn btn-search">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </li>

                <!-- Dark Mode Test -->
                <!-- <li v-xe-pm.E>
          <xe-checkbox
            v-model="pageOptions.pageContentInverseMode"
            label="Dark"
            tooltip="Dark Mode"
            @change="onChangeInverseMode"
          />
        </li> -->

                <!-- 시계 -->
                <li v-if="!isMobile">
                    <clock-top @change-alarm="onChangeAlarms" />
                </li>
                <li v-if="isWscConnected == false">
                    <a href="javascript:;" v-b-tooltip.hover :title="$t('서버와의 연결이 끊어졌습니다.')">
                        <i class="fa fa-unlink" />
                    </a>
                </li>

                <!-- 기상 -->
                <!-- <li class="dropdown">
          <weather-top />
        </li> -->
                <!-- 알람 -->
                <alarm-top :isChangeAlarm="isChangeAlarm" />
                <li
                    class="d-flex align-items-center"
                    style="cursor: pointer; font-size: 1.2rem; padding: 10px 10px 10px 10px;"
                    v-b-tooltip.hover
                    title="환산계수 조회"
                    @click="showConversionModal"
                >
                    <i class="fas fa-retweet"> </i>
                </li>

                <li
                    class="d-flex align-items-center"
                    v-b-toggle.sidebar-1
                    v-b-tooltip.hover
                    title="관제점 조회"
                    @click="showSideBar"
                    style="cursor: pointer; font-size: 1.2rem; padding: 10px 10px 10px 10px;"
                >
                    <!-- <button v-b-toggle.sidebar-1 type="button" class="btn btn-primary" @click="showSideBar">관제점 조회</button> -->
                    <i class="fas fa-th-list"></i>
                </li>

                <!-- 언어 : 20210217 다국어 숨김 -->
                <!-- 다국어 일시 활성화 -->
                <!-- <locale-top /> -->
                <!-- 프로필 -->
                <user-top />

                <!-- 오른쪽 사이드바 토글 버튼 -->
                <li class="divider d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar"></li>
                <li class="d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar">
                    <!-- toggleRightSidebar -->
                    <a href="javascript:;" v-on:click="toggleRightSidebarCollapsed" class="f-s-14">
                        <i class="fa fa-th"></i>
                    </a>
                </li>
            </ul>
            <b-sidebar
                ref="PointSideBar"
                id="sidebar-1"
                bg-variant="white"
                backdrop-variant="secondary"
                width="45vw"
                @hidden="closeSidebar"
                no-header
                shadow
                right
                backdrop
            >
                <div v-if="sideBar" style="padding: 1rem; height: 100%">
                    <SearchComponent :type="'global'" @close="closeSidebar" />
                </div>
            </b-sidebar>
        </div>

        <b-modal
            class="convertUnitModal"
            id="conversion-modal"
            ref="conversionModal"
            title="환산계수 조회"
            size="lg"
            hide-footer
        >
            <table class="table text-center">
                <thead>
                    <tr>
                        <th class="convertModalHeader">에너지 종류</th>
                        <th class="convertModalHeader">현재 단위</th>
                        <th class="convertModalHeader">변환 대상</th>
                        <th class="convertModalHeader">계수 (base)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(conversion, index) in flatConversionFactors" :key="index" class="convertModalBody">
                        <td class="bodyTd align-middle" v-if="conversion._rowspan" :rowspan="conversion._rowspan">
                            {{ convertTypeLabel(conversion.energyType) }}
                        </td>
                        <td class="bodyTd">{{ conversion.unit }}</td>
                        <td class="bodyTd">{{ conversion.to }}</td>
                        <td class="bodyTd">{{ conversion.base }}</td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </div>
</template>

<script>
//import PageOptions from "../.. /config/PageOptions.vue";
import HeaderMegaMenu from "./HeaderMegaMenu.vue";

import ClockTop from "./ClockTop.vue";
// import WeatherTop from "./WeatherTop.vue";
import AlarmTop from "./AlarmTop.vue";
// import LocaleTop from "./LocaleTop.vue";
import UserTop from "./UserTop.vue";
import SearchComponent from "@src/views/component/v2.1/PointSearch/Search.vue";
import xelib from "xelib";
import { mapState } from "vuex";
const { Unit } = xelib.PointBoxV2;
export default {
    name: "Header",
    components: {
        HeaderMegaMenu,
        ClockTop,
        // WeatherTop,
        AlarmTop,
        // LocaleTop,
        UserTop,
        SearchComponent,
    },
    data() {
        return {
            //pageOptions: PageOptions,
            searchText: "",
            isChangeAlarm: false,
            logo: require("/public/assets/xems/common/logo/logo-header.png"),

            timeout: null,
            timer: { stopped: true, time: 0 },
            sideBar: false,
            conversionFactors: {},
        };
    },
    computed: {
        ...mapState({
            unitTypes: (state) => state.unitTypes,
        }),
        flatConversionFactors() {
            const result = [];
            for (const energyType in this.conversionFactors) {
                const items = this.conversionFactors[energyType];
                const entries = Object.values(items).map((item) => ({
                    energyType,
                    ...item,
                }));

                // 첫 항목에만 rowspan 정보 추가
                if (entries.length > 0) {
                    entries[0]._rowspan = entries.length;
                }

                result.push(...entries);
            }
            return result;
        },
        master() {
            return this.$store.getters.master;
        },
        masterLogo() {
            if (!this.isEmpty(this.master.logoInvrese)) {
                return this.pageOptions.pageHeaderInverseMode == false ? this.master.logoInvrese : this.master.logo;
            }
            return this.master.logo;
        },
        labelTheme() {
            // 사용자 지정 우선
            return localStorage.labelTheme || this.master.labelTheme || "blue";
        },
        isWscConnected() {
            return this.$store.getters.isWscConnected;
        },
        weather() {
            return this.$store.getters.weather;
        },
        isEditMode() {
            return this.$store.getters.isEditMode;
        },
    },
    watch: {
        // "pageOptions.pageContentInverseMode"() {
        //   // PageOptions.pageContentInverseMode = this.pageOptions.pageContentInverseMode;
        //   console.log("Header Watch", this.pageOptions.pageContentInverseMode ? "Inverse" : "Normal");
        // },
    },
    mounted() {
        this.$store.dispatch("UPDATE_WEATHER");
        this.conversionFactors = Unit.getConversionFactors();
    },
    methods: {
        convertTypeLabel(type) {
            return this.unitTypes?.find((item) => item.value === type).text;
        },
        showConversionModal() {
            this.$refs.conversionModal.show();
        },
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.PointSideBar.hide();
            console.log("adsfasdfasdf");
            this.sideBar = false;
        },
        toggleMobileSidebar() {
            this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
        },
        toggleMobileRightSidebar() {
            this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
        },
        toggleMobileTopMenu() {
            this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
        },
        toggleMobileMegaMenu() {
            this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
        },
        toggleRightSidebar() {
            this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
        },
        toggleRightSidebarCollapsed() {
            this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
        },
        searchForm: function(e) {
            e.preventDefault();
            this.$router.push({
                name: "search",
                params: { searchText: this.searchText },
            });
        },
        openWeatherDetail() {
            this.$router.push(`/operation/weather`);
        },
        onChangeAlarms() {
            this.isChangeAlarm = !this.isChangeAlarm;
        },
        onChangeInverseMode(checked) {
            this.pageOptions.pageContentInverseMode = checked;
        },
    },
};
</script>
<style scoped>
.convertModalHeader {
    font-size: 15px;
    padding-left: 1rem;
    font-weight: 800;
}
.convertModalBody {
    font-size: 14px;
    font-weight: bold;
}
.bodyTd {
    padding: 1rem 0.7rem;
}
</style>
