/*********************************************
 *             팝업 메세지 정의                *
 *        (postfix : POPUP_MESSAGE)           *
 *********************************************/

//공통 (prefix : COMMON)

/** 공통 오류 메시지 */
export const COMMON_API_CALL_FAIL_MESSAGE = "서버 호출 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요.";

/** 공통 NO_DATA 메시지 */
export const COMMON_NO_DATA_POPUP_MESSAGE = "데이터가 없습니다.";

/** 공통 no search data 메시지 */
export const COMMON_NO_SEARCH_DATA_POPUP_MESSAGE = "데이터를 조회할 수 없습니다.";

/** 공통 분석 NO_DATA 메시지 */
export const COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE = "분석할 데이터가 없습니다.";

/** 공통 분석 COMPDATA_NO_DATA 메시지 */
export const COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE = "비교할 데이터가 없습니다.";

/** 공통 조회 NO_DATA 메시지 */
export const COMMON_SEARCH_NO_DATA_POPUP_MESSAGE = "조회할 데이터가 없습니다.";

/** 공통 데이터 등록 완료 메시지 */
export const COMMON_DATA_ADD_POPUP_MESSAGE = "데이터를 등록하였습니다.";

/** 공통 데이터 저장 완료 메시지 */
export const COMMON_DATA_SAVE_POPUP_MESSAGE = "데이터를 저장하였습니다.";

/** 공통 저장 확인 메시지 */
export const COMMON_SAVE_CONFIRM_POPUP_MESSAGE = "저장하시겠습니까?";

/** 공통 저장 완료 메시지 */
export const COMMON_SAVE_POPUP_MESSAGE = "저장하였습니다.";

/** 공통 수정 확인 메시지 */
export const COMMON_UPDATE_CONFIRM_POPUP_MESSAGE = "수정하시겠습니까?";

/** 공통 수정 완료 메시지 */
export const COMMON_UPDATE_POPUP_MESSAGE = "수정하였습니다.";

/** 공통 삭제 확인 메시지 */
export const COMMON_DELETE_CONFIRM_POPUP_MESSAGE = "삭제하시겠습니까?";

/** 공통 삭제 완료 메시지 */
export const COMMON_DELETE_POPUP_MESSAGE = "삭제하였습니다.";

/** 공통 삭제할 목록 Noti 메시지 */
export const COMMON_DELETE_NOTI_POPUP_MESSAGE = "삭제할 목록을 선택하세요.";

/** 공통 저장 항목 NO_DATA 메시지 */
export const COMMON_SAVE_NO_DATA_POPUP_MESSAGE = "저장할 항목을 선택하세요.";

/** 공통 저장 및 수정 항목 NO_DATA 메시지 */
export const COMMON_SAVE_MODIFY_NO_DATA_POPUP_MESSAGE = "저장할 항목을 선택하세요.";

/** 공통 삭제 항목 NO_DATA 메시지 */
export const COMMON_REMOVE_NO_DATA_POPUP_MESSAGE = "삭제할 항목을 선택하세요.";

/** 공통 저장 실패 메시지 */
export const COMMON_SAVE_ERROR_POPUP_MESSAGE = "저장에 실패하였습니다. 저장 내역을 확인후에 다시 시도해 주세요.";

/** 공통 삭제 실패 메시지 */
export const COMMON_REMOVE_ERROR_POPUP_MESSAGE = "삭제에 실패하였습니다. 삭제 내역을 확인후에 다시 시도해 주세요.";

/** 공통 사용중인 정보 삭제 실패 메시지 */
export const COMMON_INUSE_REMOVE_ERROR_POPUP_MESSAGE = "다른 곳에서 사용중인 정보이므로 삭제할 수 없습니다.";

/** 공통 인증 정보 없음 메시지 */
export const COMMON_NO_CERTIFICATION_INFO_POPUP_MESSAGE = "인증 정보가 없습니다. 로그인 페이지로 이동합니다.";

/** 공통 엑셀 다운로드 메시지 */
export const COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE = "다운로드 하시겠습니까?";

/** 공통 엑셀 다운로드 시작 메시지 */
export const COMMON_EXCEL_DOWNLOAD_START_POPUP_MESSAGE = "다운로드를 시작합니다.";

/** 공통 다운로드 성공 메시지 */
export const COMMON_DOWNLOAD_SUCCESS_POPUP_MESSAGE = "다운로드가 완료되었습니다.";

/** 공통 엑셀 다운로드 오류 메시지 */
export const COMMON_EXCEL_DOWNLOAD_ERROR_POPUP_MESSAGE = "엑셀 다운로드에 실패하였습니다.";

/** 공통 차트 조회 조회기간 오류 메시지 */
export const COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE = "조회기간을 잘못 설정하였습니다.";

/** 공통 사용여부 유효성 검사 메시지 */
export const COMMON_USAGE_VALID_POPUP_MESSAGE = "사용여부를 선택하세요.";

/** 공통 입력 내용 오류 메시지 */
export const COMMON_INPUT_ERROR_POPUP_MESSAGE = "입력한 내용이 올바르지 않습니다.";

/** 공통 입력 내용 중복 메시지 */
export const COMMON_INPUT_DUP_ERROR_POPUP_MESSAGE = "입력한 내용이 중복되었습니다.";

/** 공통 권한 없는 메뉴 접속 오류 메세지 */
export const COMMON_ROUTE_AUTH_ERROR_POPUP_MESSAGE = "잘못된 경로로 접근하셨습니다.";

/** 공통 상태 변경 성공 메시지 */
export const COMMON_STATUS_CHANGE_SUCCESS_POPUP_MESSAGE = "변경하였습니다.";

/** 공통 상태 변경 선택 목록 Noti 메시지 */
export const COMMON_STATUS_CHANGE_NOTI_POPUP_MESSAGE = "변경할 목록을 선택하세요.";

/** 공통 상태 변경 실패 메시지 */
export const COMMON_STATUS_CHANGE_ERROR_POPUP_MESSAGE =
    "변경에 실패하였습니다. 변경 내역을 확인후에 다시 시도해 주세요.";

/** 공통 편집중인 내용 무싷하고 종료 메시지 */
export const COMMON_EDIT_CANCEL_POPUP_MESSAGE = "편집중인 내용을 무시하고 종료하시겠습니까?";

/** 공통 데이터 추가 메시지 */
export const COMMON_DATA_ADD_DATA_POPUP_MESSAGE = "데이터를 추가하였습니다.";

/** 공통 갱신 메시지 */
export const COMMON_REFRESH_POPUP_MESSAGE = "갱신하였습니다.";

/** 공통 갱신 실패 메시지 */
export const COMMON_REFRESH_ERROR_POPUP_MESSAGE = "갱신에 실패하였습니다. 다시 시도해주세요.";

// 로그인 (prefix : LOGIN)

/** 로그인 실패 메시지 */
export const LOGIN_FAILURE_POPUP_MESSAGE = "아이디 또는 비밀번호가 일치하지 않습니다.";

/** 마스터 코드 미선택 메시지 */
export const LOGIN_MASTER_CODE_SELECT_POPUP_MESSAGE = "마스터 코드를 선택하세요.";

/** 최초 접속 메시지 */
export const LOGIN_FIRST_ACCESS_POPUP_MESSAGE = "최초 접속 아이디입니다. 비밀번호를 변경해주세요.";

// 회원가입 (prefix : REGISTER)

/** 회원가입 여부 메시지 */
export const REGISTER_SIGNUP_POPUP_MESSAGE = "회원가입 하시겠습니까?";

/** 회원가입 실패 메시지 */
export const REGISTER_FAILURE_POPUP_MESSAGE = "회원가입에 실패하였습니다. 관리자에게 문의해주세요.";

/** 회원가입 요청 메시지 */
export const REGISTER_REQUEST_POPUP_MESSAGE = "회원가입을 요청하였습니다.";

// 프로필 (prefix : PROFILE)

/** 사용자명 오류 메시지 */
export const PROFILE_USERNAME_FAILURE_VALID_POPUP_MESSAGE = "사용자명이 올바과르지 않습니다.";

/** 전화번호 오류 메시지 */
export const PROFILE_PHONE_FAILURE_VALID_POPUP_MESSAGE = "전화번호가 올바르지 않습니다.";

// 대시보드 (prefix : DASHBOARD)

/** 대시보드 제목 중복 메시지 (대시보드 편집, 대시보드 관리 적용) */
export const DASHBOARD_DATA_DUP_ERROR_POPUP_MESSAGE = "해당 이름의 대시보드가 이미 존재합니다.";

/** 대시보드 메뉴 선택 메시지 */
export const DASHBOARD_MENU_SELECT_POPUP_MESSAGE = "메뉴를 선택하세요.";

/** 대시보드 메뉴 선택 중복 메시지 */
export const DASHBOARD_USGING_ERROR_POPUP_MESSAGE = "메뉴에서 사용중입니다.";

/** 대시보드 메뉴 수정 여부 메시지 */
export const DASHBOARD_MENU_UPDATE_POPUP_MESSAGE = "메뉴를 수정하시겠습니까?";

/** 대시보드 저장 여부 메시지 */
export const DASHBOARD_SAVE_POPUP_MESSAGE = "대시보드를 저장하시겠습니까?";

/** 대시보드 제목 조건 메시지 */
export const DASHBOARD_TITLE_ERROR_VALID_POPUP_MESSAGE = "제목은 최소 1자 이상 입력해야 합니다.";

/** 대시보드 분석 템플릿 선택 메시지 */
export const DASHBOARD_ANALYSIS_TEMPLATE_VALID_POPUP_MESSAGE = "분석템플릿을 선택해주세요.";

/** 대시보드 분석 템플릿 선택 메시지 */
export const DASHBOARD_ANALYSIS_TEMPLATE_API_ERROR_POPUP_MESSAGE =
    "분석템플릿을 가져오는데 오류가 발생하였습니다. 재로그인 후 다시 시도해주세요.";

/**대시보드 삭제 여부 메시지*/
export const DASHBOARD_DELETE_POPUP_MESSAGE = "대시보드를 삭제하시겠습니까?";

/**대시보드 삭제 시 PAGE 메시지*/
export const DASHBOARD_DELETE_LESS_THAN_ONE_MESSAGE = "대시보드는 반드시 1개 이상 있어야합니다.";

// 에너지 (prefix : ENERGY)

/** 에너지 조회 조건 오류 메시지 (원시데이터) */
export const ENERGY_SEARCH_LOG_POPUP_MESSAGE = "조회기간 한계 초과 (최대 1일)";

/** 에너지 조회 조건 오류 메시지 (분단위) */
export const ENERGY_SEARCH_MIN_POPUP_MESSAGE = "조회기간 한계 초과 (최대 1일)";

/** 에너지 조회 조건 오류 메시지 (15분) */
export const ENERGY_SEARCH_QUARTER_POPUP_MESSAGE = "조회기간 한계 초과 (최대 2주)";

/** 에너지 조회 조건 오류 메시지 (시간) */
export const ENERGY_SEARCH_HOUR_POPUP_MESSAGE = "조회기간 한계 초과 (최대 1개월)";

/** 에너지 조회 조건 오류 메시지 (일간) */
export const ENERGY_SEARCH_DAY_POPUP_MESSAGE = "조회기간 한계 초과 (최대 3년)";

/** 에너지 조회 조건 오류 메시지 (주간) */
// export const ENERGY_SEARCH_WEEK_POPUP_MESSAGE = "조회기간 한계 초과 (최대 3년)";

/** 에너지 조회 조건 오류 메시지 (월간) */
export const ENERGY_SEARCH_MONTH_POPUP_MESSAGE = "조회기간 한계 초과 (최대 10년)";

/** 에너지 조회 조건 오류 메시지 (연간) */
export const ENERGY_SEARCH_YEAR_POPUP_MESSAGE = "조회기간 한계 초과 (제한없음)";

/** 에너지 조회 기준연도 미선택 메시지 */
export const ENERGY_MAIN_YEAR_VALID_POPUP_MESSAGE = "기준연도를 선택하세요.";

/** 에너지 조회 에너지원 미선택 메시지 */
export const ENERGY_MAIN_ENERGY_TYPE_VALID_POPUP_MESSAGE = "에너지원을 선택하세요.";

// #region 관제점 (prefix : POINT)

/** 관제점 등록 항목 미입력 메시지 */
export const POINT_INPUT_ESSENTIAL_VALID_POPUP_MESSAGE = " 항목을 입력하세요.";

/** 관제점 수정 완료 메시지 */
export const POINT_UPDATE_SUCCESS_POPUP_MESSAGE = "관제점을 수정하였습니다.";

/** 관제점 등록 중복 메시지 */
export const POINT_DATA_DUP_POPUP_MESSAGE = "관제점 주소가 이미 등록되어 있습니다.";

/** 관제점 계측이력조회 조회기간 오류 메시지 */
export const POINT_SMALLER_END_DATE_POPUP_MESSAGE = "종료 날짜가 시작 날짜 보다 작습니다.";

/** 관제점 공통 관제점 주소 미입력 메세지 */
export const POINT_INPUT_POINT_ADDRESS_POPUP_MESSAGE = "관제점 주소를 입력하세요.";

/** 관제점 공통 관제점명 미입력 메세지 */
export const POINT_INPUT_POINT_NAME_POPUP_MESSAGE = "관제점명을 입력하세요.";

/** 관제점 공통 관제점 그룹 미선택 메세지 */
export const POINT_SELECT_POINT_GROUP_POPUP_MESSAGE = "관제점 그룹을 선택하세요.";

/** 관제점 공통 관제점 취합유형 미선택 메세지 */
export const POINT_SELECT_POINT_COLLECTION_TYPE_POPUP_MESSAGE = "관제점 취합유형을 선택하세요.";

/** 관제점 공통 관제점 유형 미선택 메세지 */
export const POINT_SELECT_POINT_TYPE_POPUP_MESSAGE = "관제점 유형을 선택하세요.";

/** 관제점 공통 관제점 집계 유형 미선택 메세지 */
export const POINT_SELECT_TOTAL_TYPE_POPUP_MESSAGE = "집계 유형을 선택하세요.";

/** 관제점 공통 계측값 유형 미선택 메세지 */
export const POINT_SELECT_MEASUREMENT_VALUE_TYPE_POPUP_MESSAGE = "계측값 유형을 선택해주세요.";

/** 관제점 공통 활용목적 미선택 메세지 */
export const POINT_SELECT_PURPOSE_USE_POPUP_MESSAGE = "활용목적을 선택하세요.";

/** 관제점 공통 자료생성 미선택 메세지 */
export const POINT_SELECT_CREATE_DATA_POPUP_MESSAGE = "자료생성을 선택하세요.";

/** 관제점 공통 자료 획득원 미선택 메세지 */
export const POINT_SELECT_DATA_SOURCE_POPUP_MESSAGE = "자료 획득원을 선택하세요.";

/** 사용자 정의 관제점 설정 삭제 오류 메시지 */
export const POINT_CUSTOM_NEW_ITEM_DELETE_POPUP_MESSAGE = "신규 등록된 행만 제거할 수 있습니다.";

/** 데이터 수집 설정 드라이버 오류 메시지 */
export const POINT_DATA_COLLECTION_INIT_ERROR_POPUP_MESSAGE = "채널 유형에 해당하는 드라이버가 존재하지 않습니다.";

/** 데이터 수집 설정 드라이버 변경 오류 메시지 */
export const POINT_DATA_COLLECTION_DRIVER_UPDATE_ERROR_POPUP_MESSAGE =
    "현재장비와 모든 하위장비에서 사용중인 관제점이 없을때만 드라이버를 변경할 수 있습니다.";

/** 데이터 수집 설정 관제점 주소 변경 경고 메시지 */
export const POINT_DATA_COLLECTION_PTADDR_CHANGE_POPUP_MESSAGE =
    "이 변경은 연관된 관제점 주소에 영향을 미칩니다. 기존 수집된 데이터는 유지되지만 찾을 수 없게 됩니다. 이미 집계된 데이터는 영향을 받지 않습니다.";

/** 계측장치 등록 드라이버 정보 미입력 메시지 */
export const POINT_DATA_COLLECTION_ADD_DRIVER_VALID_POPUP_MESSAGE = "드라이버 정보를 입력하세요.";

/** 계측장치 등록 펄스카운터 펄스유닛 정보 미입력 메시지 */
export const POINT_DATA_COLLECTION_ADD_PULSEUNIT_VALID_POPUP_MESSAGE = "펄스 당 용량을 입력하세요.";

/** 계측장치 등록 계측기 ID 입력 조건 오류 메시지 */
export const POINT_DATA_COLLECTION_ADD_ID_VALID_POPUP_MESSAGE = "계측기 ID 는 영문과 숫자 조합으로 입력하세요.";

/** 계측장치 등록 계측기명 미입력 메시지 */
export const POINT_DATA_COLLECTION_ADD_NAME_VALID_POPUP_MESSAGE = "계측기명을 입력하세요.";

/** 데이터 수집기 설정 에이전트 변경 여부 메시지 */
export const POINT_DATA_COLLECTION_AGENT_CHANGE_POPUP_MESSAGE = "에이전트를 수정하시겠습니까?";

/** 데이터 수집기 설정 에이전트 재시작 여부 메시지 */
export const POINT_DATA_COLLECTION_AGENT_RESTART_POPUP_MESSAGE = "에이전트를 재시작하시겠습니까?";

/** 데이터 수집기 설정 에이전트 재시작 명령 전송 완료 메시지*/
export const POINT_DATA_COLLECTION_AGENT_RESTART_SEND_POPUP_MESSAGE = "에이전트 재시작 명령을 전송하였습니다.";

/** 데이터 수집기 설정 에이전트 재시작 오류 메시지 */
export const POINT_DATA_COLLECTION_AGENT_RESTART_ERROR_POPUP_MESSAGE = "할당된 클라이언트가 존재하지 않습니다.";

/** 데이터 수집 설정 - 모듈 관제점 관리 관제점 주소 삭제 여부 메시지 */
export const POINT_DATA_COLLECTION_MODULE_ADD_POPUP_MESSAGE =
    "현재 주소를 삭제하고 새 주소를 생성합니다. 진행하시겠습니까?";

/** 데이터 수집 설정 - 모듈 관제점 관리 관제점 주소 자동채우기 여부 메시지 */
export const POINT_DATA_COLLECTION_MODULE_DELETE_POPUP_MESSAGE =
    "선택된 모든 주소가 모두 제거됩니다. 진행하시겠습니까?";

/** 데이터 수집 설정 - 모듈 관제점 관리 관제점 저장 여부 메시지  */
export const POINT_DATA_COLLECTION_MODULE_SAVE_POPUP_MESSAGE = "변경된 관제점을 저장하시겠습니까?";

/** 관제점 계산식 관리 (워크플로우 공통) 이름 미입력 메시지 */
export const POINT_CALCULATION_WORKFLOW_NAME_VALID_POPUP_MESSAGE = "워크플로우명을 입력하세요.";

/** 관제점 계산식 관리 (워크플로우 공통) 워크플로우 중복 메시지 */
export const POINT_CALCULATION_WORKFLOW_DATA_DUP_CHECK_POPUP_MESSAGE = "해당 워크플로우가 이미 존재합니다.";

/** 관제점 계산식 관리 (워크플로우 공통) 워크플로우 등록 여부 메시지 */
export const POINT_CALCULATION_WORKFLOW_ADD_POPUP_MESSAGE = "워크플로우를 저장하시겠습니까?";

/** 관제점 계산식 관리 (워크플로우 공통) 워크플로우 삭제 여부 메시지 */
export const POINT_CALCULATION_WORKFLOW_DELETE_POPUP_MESSAGE = "워크플로우를 삭제하시겠습니까?";

/** 관제점 계산식 관리 (워크플로우 공통) 워크플로우 그룹 저장 여부 메시지 */
export const POINT_CALCULATION_GROUP_SAVE_POPUP_MESSAGE = "워크플로우 그룹을 저장하시겠습니까?";

/** 관제점 데이터 조회 데이터 동기화 요청 메시지 */
export const POINT_DATA_SEARCH_COMMON_POPUP_MESSAGE = "데이터 동기화를 요청하였습니다.";

/** 관제점 데이터 조회 샘플링 진행 여부 메시지 */
export const POINT_DATA_SEARCH_SAMPLING_POPUP_MESSAGE = "샘플링을 진행하시겠습니까?";

/** 관제점 데이터 조회 데이터 취합 여부 메시지 */
export const POINT_DATA_SEARCH_COLLECTION_POPUP_MESSAGE = "취합을 진행하시겠습니까?";

/** 관제점 데이터 조회 캐시 삭제 여부 메시지 */
export const POINT_DATA_SEARCH_CASH_DELETE_POPUP_MESSAGE = "캐시 삭제를 진행하시겠습니까?";

// 요금 (prefix : COST)

/** 요금 정보 등록 완료 메시지 */
export const COST_REGIST_POPUP_MESSAGE = "요금 정보를 등록하였습니다.";

/** 요금 정보 등록 요금제 미선택 메시지  */
export const COST_REGIST_PLAN_VALID_POPUP_MESSAGE = "요금제를 선택하세요.";

/** 요금 정보 일괄 삭제 여부 메시지 */
export const COST_DELETE_POPUP_MESSAGE = "일괄 삭제하시겠습니까?";

/** 요금 정보 일괄 삭제 완료 메시지 */
export const COST_DELETE_SUCCESS_POPUP_MESSAGE = "요금정보를 일괄 삭제하였습니다.";

/** 요금 정보 저장 완료 메시지 */
export const COST_SAVE_POPUP_MESSAGE = "계절요금 구분 및 시간대를 저장하였습니다.";

/** 요금 정보 저장 시간대 요금 구분 오류 메시지 */
export const COST_SAVE_TIME_VALID_POPUP_MESSAGE = "시간대 요금 구분을 확인하세요.";

/** 요금 정보 저장 월 계절 요금 미선택 메시지 */
export const COST_SAVE_SEASON_VALID_POPUP_MESSAGE = "월 계절 요금 구분을 선택하세요.";

/** 요금 정보 등록 사용 에너지원 미선택 메시지 */
export const COST_REGIST_ENERGY_VALID_POPUP_MESSAGE = "사용 에너지원을 선택하세요.";

/** 요금 정보 저장시 이미 등록되어 있을 경우 메시지 */
export const COST_DUP_ERROR_POPUP_MESSAGE = "요금제 정보가 이미 등록되어 있습니다.";

/** 요금표 관리 저장 요금 기간 중복 메시지 */
export const COST_TABLE_DATE_OVERLAP_ERROR_POPUP_MESSAGE = "요금기간이 이미 등록되어 있습니다.";

/** 요금표 관리 저장 완료 메시지 */
export const COST_TABLE_REGIST_SUCCESS_POPUP_MESSAGE = "요금표를 저장하였습니다.";

/** 요금표 관리 수정 완료 메시지 */
export const COST_TABLE_UPDATE_SUCCESS_POPUP_MESSAGE = "요금표를 수정하였습니다.";

/** 요금표 관리 삭제 완료 메시지 */
export const COST_TABLE_DELETE_SUCCESS_POPUP_MESSAGE = "요금표를 삭제하였습니다.";

export const COST_TABLE_DATE_REQUIRED_DATE_POPUP_MESSAGE = "적용 기간을 입력해주세요.";

/** 적용 기간이 잘못되었을 경우 메세지 */
export const COST_TABLE_WRONG_DATE_POPUP_MESSAGE = "적용 기간이 잘못되었습니다.";

// export const COST_TABLE_DATE_REQUIRED_END_DATE_POPUP_MESSAGE = "요금기간이 이미 등록되어 있습니다.";

/** 요금계산 관제점 필수 메세지 */
export const COST_TABLE_POINT_ADDR_REQUIRED_POPUP_MESSAGE = "요금계산 관제점을 선택해주세요.";

/** 적용 관제점 등록 완료 메시지 */
export const COST_APPLY_POINT_REGIST_POPUP_MESSAGE = "적용 관제점을 등록하였습니다.";

/** 적용 관제점 삭제 완료 메시지 */
export const COST_APPLY_POINT_DELETE_SUCCESS_POPUP_MESSAGE = "적용 관제점을 삭제하였습니다.";

/** 적용 관제점 수정 완료 메시지 */
export const COST_APPLY_POINT_UPDATE_SUCCESS_POPUP_MESSAGE = "적용 관제점을 수정하였습니다.";

/** 적용 관제점 중복 등록 오류 메시지 */
export const COST_APPLY_POINT_REGIST_DUP_ERROR_POPUP_MESSAGE = "적용 관제점이 이미 다른 요금제에 등록되어 있습니다.";

// 코드 (prefix : CODE)

/** 코드 등록 및 수정 완료 메시지 (공통코드, 단위코드 적용) */
export const CODE_REGIST_POPUP_MESSAGE = () => global.xe.$mt("건을 저장하였습니다.");

/** 코드 등록 및 수정 오류 메시지 (공통코드, 단위코드 적용) */
export const CODE_REGIST_FAIL_POPUP_MESSAGE = "저장할 항목을 선택하세요.";

/** 코드 등록 대분류 코드 미입력 메시지 */
export const CODE_LARGER_CODE_REQUIRED_VALID_POPUP_MESSAGE = "대분류 코드를 입력하세요.";

/** 코드 등록 대분류 코드명 미입력 메시지 */
export const CODE_LARGER_CODE_NAME_REQUIRED_VALID_POPUP_MESSAGE = "대분류 코드명을 입력하세요.";

/** 코드 등록 소분류 코드 미입력 메시지 */
export const CODE_SMALL_CODE_REQUIRED_VALID_POPUP_MESSAGE = "소분류 코드를 입력하세요.";

/** 코드 등록 소분류 코드명 미입력 메시지 */
export const CODE_SMALL_CODE_NAME_REQUIRED_VALID_POPUP_MESSAGE = "소분류 코드명을 입력하세요.";

/** 코드 삭제 완료 메시지 (공통코드, 단위코드 적용) */
export const CODE_DELETE_SUCCESS_POPUP_MESSAGE = () => global.xe.$mt("건을 삭제하였습니다.");

/** 계측기 코드 등록 유형코드 미입력 메시지 */
export const CODE_METER_REGIST_CODE_VALID_POPUP_MESSAGE = "계측기 유형코드를 입력하세요.";

/** 계측기 코드 등록 유형명 미입력 메시지 */
export const CODE_METER_REGIST_NAME_VALID_POPUP_MESSAGE = "계측기 유형명을 입력하세요.";

/** 운영 코드 관리 저장 운영 코드 미입력 메시지*/
export const CODE_INFO_CODE_VALID_POPUP_MESSAGE = "운영 코드를 입력하세요.";

/** 운영 코드 관리 저장 운영 코드명 미입력 메시지*/
export const CODE_INFO_NAME_VALID_POPUP_MESSAGE = "운영 코드명을 입력하세요.";

/** 운영 코드 관리 저장 항목 구분 미선택 메시지*/
export const CODE_INFO_ITEM_VALID_POPUP_MESSAGE = "항목 구분을 선택하세요.";

/** 운영 코드 관리 저장 구역 미선택 메시지*/
export const CODE_INFO_ZONE_VALID_POPUP_MESSAGE = "구역을 선택하세요.";

// #region 계통 및 설비 (prefix : SYSTEM_INFO)

/** 계통 기준정보 등록, 수정 계통 코드 미입력 메시지 */
export const SYSTEM_INFO_CODE_VALID_POPUP_MESSAGE = "계통 코드를 입력하세요.";

/** 계통 기준정보 등록, 수정 계통명 미입력 메시지 */
export const SYSTEM_INFO_NAME_VALID_POPUP_MESSAGE = "계통명을 입력하세요.";

// #region 설비 (prefix : EQUIP)

/** 설비 기준정보 등록, 수정 공통 설비 모델명 미입력 메시지 */
export const EQUIP_MODEL_NAME_VALID_POPUP_MESSAGE = "설비 모델명을 입력하세요.";

/** 설비 기준정보 등록, 수정 공통 설비 유형 코드 미입력 메시지 */
export const EQUIP_CODE_VALID_POPUP_MESSAGE = "설비유형 코드를 입력하세요.";

/** 설비 기준정보 등록, 수정 공통 설비 모델 버전 미입력 메시지 */
export const EQUIP_MODEL_VERSION_VALID_POPUP_MESSAGE = "설비 모델 버전을 입력하세요.";

/** 설비 기준정보 등록, 수정 공통 통신 프로토콜 미선택 메시지 */
export const EQUIP_SELECT_PROTOCOL_VALID_POPUP_MESSAGE = "통신 프로토콜을 선택하세요.";

/** 설비 기준정보 등록, 수정 공통 설비 유형명 미입력 메시지 */
export const EQUIP_NAME_VALID_POPUP_MESSAGE = "설비 유형명을 입력하세요.";

/** 설비 기준정보 등록, 수정 공통 대분류 코드 미입력 메시지 */
export const EQUIP_INSERT_LARGE_CODE_VALID_POPUP_MESSAGE = "대분류 코드를 입력하세요.";

/** 설비 기준정보 등록, 수정 공통 대분류 코드명 미이렵 메시지 */
export const EQUIP_INSERT_LARGE_CODE_NAME_VALID_POPUP_MESSAGE = "대분류 코드명을 입력하세요.";

/** 설비 수집 항목, 설비 제어 항목 공통 등록 내용 오류 메시지 */
export const EQUIP_CATEGORY_REGIST_FAIL_POPUP_MESSAGE = "등록된 내용이 없습니다.";

/** 설비 수집 항목 삭제 항목 미선택 메시지 */
export const EQUIP_COLLECT_NO_DELETE_VALID_POPUP_MESSAGE = "삭제할 항목을 선택하세요.";

/** 설비 수집 항목 저장 항목코드 미입력 메시지 */
export const EQUIP_COLLECT_CODE_VALID_POPUP_MESSAGE = "항목코드를 입력하세요.";

/** 설비 수집 항목 저장 항목명 미입력 메시지 */
export const EQUIP_COLLECT_NAME_VALID_POPUP_MESSAGE = "항목명을 입력하세요.";

/** 설비 수집 항목 저장 제어옵션 미선택 메시지 */
export const EQUIP_COLLECT_CONTROL_VALID_POPUP_MESSAGE = "제어옵션을 선택하세요.";

/** 설비 수집 항목 저장 정렬순서 미입력 메시지 */
export const EQUIP_COLLECT_SORTING_VALID_POPUP_MESSAGE = "정렬순서를 입력하세요.";

/** 설비 기기 공통 설비 유형 미선택 메시지 */
export const EQUIP_DEVICE_TYPE_VALID_POPUP_MESSAGE = "설비 유형을 선택하세요.";

/** 설비 기기 공통 설비 유형 미입력 메시지 */
export const EQUIP_INSERT_DEVICE_TYPE_VALID_POPUP_MESSAGE = "설비 유형을 입력하세요.";

/** 설비 기기 공통 설비명 미선택 메시지 */
export const EQUIP_DEVICE_NAME_POPUP_MESSAGE = "설비명을 선택하세요.";

/** 설비 기기 공통 설비명 미입력 메시지 */
export const EQUIP_DEVICE_NAME_INPUT_VALID_POPUP_MESSAGE = "설비명을 입력하세요.";

/** 설비 기기 공통 설비 계통 미선택 메시지 */
export const EQUIP_DEVICE_SYSTEM_CODE_VALID_POPUP_MESSAGE = "설비 계통를 선택하세요.";

/** 설비 기기 공통 설비 계통 미입력 메시지 */
export const EQUIP_DEVICE_SYSTEM_CODE_INPUT_VALID_POPUP_MESSAGE = "설비 계통를 입력하세요.";

/** 설비 기기 공통 설비 용도 미선택 메시지 */
export const EQUIP_DEVICE_USAGE_CODE_VALID_POPUP_MESSAGE = "설비 용도를 선택하세요.";

/** 설비 기기 공통 설비 그룹명 미입력 메시지 */
export const EQUIP_DEVICE_GRUOP_VALID_POPUP_MESSAGE = "설비 그룹명을 입력해주세요.";

/** 설비 관제점 유형 삭제 항목 미선택 메시지 */
export const EQUIP_POINT_CONTROL_TYPE_DELETE_SELECT_POPUP_MESSAGE = "삭제할 항목을 선택하세요.";

// #region 휴일 관리 (prefix : HOLIDAY)

/** 휴일 관리 삭제 여부 메시지 */
export const HOLIDAY_DELETE_POPUP_MESSAGE = "삭제하시겠습니까?";

/** 휴일 관리 삭제 오류 메시지 */
export const HOLIDAY_DELETE_NEW_ITEM_DELETE_POPUP_MESSAGE = "신규 등록된 행만 제거할 수 있습니다.";

/** 휴일 관리 삭제 삭제 항목 미선택 메시지 */
export const HOLIDAY_DELETE_SELECT_ERROR_POPUP_MESSAGE = "삭제할 항목을 선택하세요.";

/** 휴일 관리 휴일 구분 미선택 메시지 */
export const HOLIDAY_DIVISION_VALID_POPUP_MESSAGE = "휴일 구분을 선택하세요.";

/** 휴일 관리 조회기간 미선택 메시지 */
export const HOLIDAY_SEARCH_PERIOD_VALID_POPUP_MESSAGE = "조회기간을 선택하세요.";

// #region 구역 (prefix : ZONE)

/** 구역 등록 여부 메시지 */
export const ZONE_ADD_POPUP_MESSAGE = " 구역 하위에 저장하시겠습니까?";

/** 구역 수정, 등록 구역명 미입력 메세지 */
export const ZONE_UPDATE_NAME_VALID_POPUP_MESSAGE = "구역명을 입력하세요.";

/** 구역 수정, 등록 구역 구분 미선택 메세지 */
export const ZONE_UPDATE_DIV_VALID_POPUP_MESSAGE = "구역 구분을 선택하세요.";

/** 구역 수정, 등록 상위 구역 미입력 메세지 */
export const ZONE_UPDATE_UPPER_VALID_POPUP_MESSAGE = "상위 구역을 입력하세요.";

/** 구역 수정, 등록 상위 구역 미선택 메세지 */
export const ZONE_UPDATE_UPPER_SELECT_VALID_POPUP_MESSAGE = "상위 구역을 선택하세요.";

/** 구역 수정, 등록 구역 미선택 메세지 */
export const ZONE_UPDATE_ZONE_VALID_POPUP_MESSAGE = "구역을 선택하세요.";

/** 구역과 상위 구역이 동일 시 메세지 */
export const ZONE_SAME_UPPER_ZONE_POPUP_MESSAGE = "상위구역은 현재 구역과 동일 할 수 없습니다.";

// #region EHP (prefix : EHP)

/** EHP 실내 최대 온도 설정 제한 메시지 */
export const EHP_CONTROL_TEMP_MAX_POPUP_MESSAGE = "실내 온도는 최대 30℃까지 설정 가능합니다.";

/** EHP 실내 최저 온도 설정 제한 메시지 */
export const EHP_CONTROL_TEMP_MIN_POPUP_MESSAGE = "실내 온도는 최소 18℃까지 설정 가능합니다.";

/** EHP 조회 구역 구분 미선택 메시지 */
export const EHP_ZONE_SELECT_ERROR_POPUP_MESSAGE = "구역 구분을 선택하세요.";

/** EHP 조회 설비 기기 미선택 메시지 */
export const EHP_EQUIP_SELECT_ERROR_POPUP_MESSAGE = "설비 기기를 선택하세요.";

// #region 실내 환경정보 (prefix : ENVIROMENT)
/** 실내 환경정보 조회 구역 미선택 메시지 */
export const ENVIROMENT_ZONE_SELECT_ERROR_POPUP_MESSAGE = "구역을 선택하세요.";

/** 실내 환경정보 조회 구분 미선택 메시지 */
export const ENVIROMENT_SORTATION_SELECT_ERROR_POPUP_MESSAGE = "구분을 선택하세요.";

// #region 검교정 이력 (prefix : CLBRTNHIST)

/** 검교정 이력 등록 검교정 내역 미입력 메시지 */
export const CLBRTNHIST_REGIST_DES_VALID_POPUP_MESSAGE = "검교정 내역을 입력하세요.";

/** 검교정 이력 관제점 유형 미선택 메세지 */
export const CLBRTNHIST_POINT_TYPE_VALID_POPUP_MESSAGE = "관제점 유형을 선택하세요.";

/** 검교정 이력 처리 일자 미선택 메세지 */
export const CLBRTNHIST_PROCESSING_DATE_VALID_POPUP_MESSAGE = "처리 일자를 선택하세요.";

/** 신규 검교정 이력 저장 확인 메세지 */
export const CLBRTNHIST_SAVE_CONFIRM_POPUP_MESSAGE = "신규 검교정 내역을 저장하시겠습니까?";

/** 검교정 유형 오류 메세지 */
export const CLBRTNHIST_NONE_DATA_TYPE_POPUP_MESSAGE = "검교정 유형을 선택하세요.";

// #region 알람 (prefix : ALARM)

/** 알람 등록 오류 메시지 */
export const ALARM_ADD_GROUP_POINT_ERROR_POPUP_MESSAGE =
    "그룹별로 관제점으로 한개의 알람만을 설정할 수 있습니다. 그룹명을 변경하여 입력하세요.";

/** 알람테스트 실행 여부 메시지 */
export const ALARM_TEST_POPUP_MESSAGE = "알람테스트를 실행하시겠습니까?";

/** 알람테스트 실행 완료 메시지 */
export const ALARM_TEST_SUCCESS_POPUP_MESSAGE = "알람테스트를 실행하였습니다.";

/** 알람 일시 중지 여부 메시지 */
export const ALARM_STOP_POPUP_MESSAGE = "알람을 일시 중지하시겠습니까?";

/** 알람 일시 중지 완료 메시지 */
export const ALARM_STOP_SUCCESS_POPUP_MESSAGE = "알람을 일시 중지하였습니다.";

/** 알람 재개 여부 메시지 */
export const ALARM_RESUME_POPUP_MESSAGE = "알람을 재개하시겠습니까?";

/** 알람 재개 완료 메시지 */
export const ALARM_RESUME_SUCCESS_POPUP_MESSAGE = "알람을 재개하였습니다.";

// #region 데이터 (prefix : DATA)

/** 백업 관리 공통 데이터 동기화 요청 여부 메시지 */
export const DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE = "데이터 동기화를 요청하였습니다.";

/** 백업 관리 공통 DB 백업 여부 메시지 */
export const DATA_BACKUP_COMMON_DB_BACKUP_POPUP_MESSAGE = "DB 백업을 진행하시겠습니까?";

/** 백업 관리 공통 테이블 통계 갱신 여부 메시지 */
export const DATA_BACKUP_COMMON_TABLE_RENEWAL_POPUP_MESSAGE = "테이블 통계를 갱신하시겠습니까?";

/** 백업 관리 공통 삭제 여부 메시지 */
export const DATA_BACKUP_COMMON_RUN_AFTER_DELETE_POPUP_MESSAGE = "삭제 후 진행하시겠습니까?";

/** 백업 관리 공통 진행 여부 메시지 */
export const DATA_BACKUP_COMMON_RUN_POPUP_MESSAGE = "진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 캐시 삭제 여부 메시지 */
export const DATA_BACKUP_CASH_DELETE_POPUP_MESSAGE = "캐시를 삭제하시겠습니까?";

/** 백업 관리 (데이터 백업) 드라이버 등록 여부 메시지 */
export const DATA_BACKUP_DRIVER_REGIST_POPUP_MESSAGE = "드라이버를 등록하시겠습니까?";

/** 백업 관리 (데이터 백업) 데이터 초기화 여부 메시지 */
export const DATA_BACKUP_DATA_INITIALIZATION_POPUP_MESSAGE = "데이터 초기화를 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 드라이버 초기화 여부 메시지 */
export const DATA_BACKUP_DRIVER_INITIALIZATION_POPUP_MESSAGE = "드라이버 초기화를 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 관제점 초기화 여부 메시지 */
export const DATA_BACKUP_POINT_INITIALIZATION_POPUP_MESSAGE = "관제점 초기화를 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) LAST 보간 진행 여부 메시지 */
export const DATA_BACKUP_LAST_POPUP_MESSAGE = "LAST 보간을 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 재샘플링 여부 메시지 */
export const DATA_BACKUP_RESAMPLING_POPUP_MESSAGE = "재샘플링을 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 재취합 여부 메시지 */
export const DATA_BACKUP_RECOLLECTION_POPUP_MESSAGE = "재취합을 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 시스템 로그 삭제 여부 메시지 */
export const DATA_BACKUP_EVENTLOG_DELETE_POPUP_MESSAGE = "시스템 로그를 삭제하시겠습니까?";

/** 백업 관리 (데이터 백업) 서버 로그 삭제 여부 메시지 */
export const DATA_BACKUP_SERVERLOG_DELETE_POPUP_MESSAGE = "서버 로그를 삭제하시겠습니까?";

/** 백업 관리 (데이터 백업) 다국어 문자열 진행 여부 메시지 */
export const DATA_BACKUP_MULTILINGUAL_POPUP_MESSAGE = "다국어 문자열을 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 메뉴 다국어 진행 여부 메시지 */
export const DATA_BACKUP_MENU_MULTILINGUAL_POPUP_MESSAGE = "메뉴 다국어를 진행하시겠습니까?";

/** 백업 관리 (데이터 백업) 최대 접속수 수정 여부 메시지 */
export const DATA_BACKUP_MAX_CONNECTION_UPDATE_POPUP_MESSAGE = "최대 접속수를 수정하시겠습니까?";

/** 백업 관리 (데이터 백업) 잠금 대기시간 수정 여부 메시지 */
export const DATA_BACKUP_LOCKDOWN_UPDATE_POPUP_MESSAGE = "잠금 대기시간을 수정하시겠습니까?";

/** 백업 관리 (데이터 백업) 호출 대기시간 수정 여부 메시지 */
export const DATA_BACKUP_CALL_WAIT_UPDATE_POPUP_MESSAGE = "호출 대기시간을 수정하시겠습니까?";

/** 백업 관리 (데이터 백업) 테이블 통계 갱신 여부 메시지 */
export const DATA_BACKUP_TABLE_STATS_UPDATE_POPUP_MESSAGE = "테이블 통계를 갱신하시겠습니까?";

/** 백업 관리 (스케줄) 스케줄 작업 진행 여부 메시지 */
export const SYSTEM_EVENT_LOG_SCHEDULE_POPUP_MESSAGE = "스케줄 작업을 실행하시겠습니까?";

/** 백업 관리 (스케줄) 스케줄 작업 진행 요청 완료 메시지 */
export const SYSTEM_EVENT_LOG_SCHEDULE_SUCCESS_POPUP_MESSAGE = "스케줄 작업 실행을 요청하였습니다.";

// #region 시스템 (prefix : SYSTEM)

/** 시스템 로그 원본 데이터 관리 데이터 복원 여부 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_SELECT_RENEWAL_POPUP_MESSAGE = "복원하시겠습니까?";

/** 시스템 로그 원본 데이터 관리 테이블 데이터 갱신 여부 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_RENEWAL_POPUP_MESSAGE = "테이블 데이터를 갱신하시겠습니까?";

/** 시스템 로그 원본 데이터 관리 테이블 데이터 갱신 완료 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_RENEWAL_SUCCESS_POPUP_MESSAGE = "테이블 데이터를 갱신하였습니다.";

/** 시스템 로그 원본 데이터 관리 테이블 데이터 삭제 여부 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_DELETE_POPUP_MESSAGE = "테이블 데이터를 삭제하시겠습니까?";

/** 시스템 로그 원본 데이터 관리 테이블 데이터 삭제 완료 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_DELETE_SUCCESS_POPUP_MESSAGE = "테이블 데이터를 삭제하였습니다.";

/** 시스템 로그 원본 데이터 관리 백업 파일 삭제 여부 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_DELETE_POPUP_MESSAGE = "백업 파일을 삭제하시겠습니까?";

/** 시스템 로그 원본 데이터 관리 백업 파일 삭제 완료 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_DELETE_SUCCESS_POPUP_MESSAGE = "백업 파일을 삭제하였습니다.";

/** 시스템 로그 원본 데이터 관리 백업 파일 복원 여부 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_RESTORE_POPUP_MESSAGE = "백업 파일을 복원하시겠습니까?";

/** 시스템 로그 원본 데이터 관리 백업 파일 복원 완료 메시지 */
export const SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_RESTORE_SUCCESS_POPUP_MESSAGE = "백업 파일을 복원하였습니다.";

// #region 권한관리 (prefix : PERMISSION)

/** 권한 관리 등록 공통 */
export const PERMISSION_COMMON_REGIST_SUCCESS_POPUP_MESSAGE = "등록하였습니다.";

export const PERMISSION_COMMON_USERGROUP_REGIST_SUCCESS_POPUP_MESSAGE = "포함하였습니다.";

export const PERMISSION_COMMON_USERGROUP_DELETE_SUCCESS_POPUP_MESSAGE = "제외하였습니다.";

/** 권한 그룹 등록 여부 메시지 */
export const PERMISSION_GROUP_ADD_POPUP_MESSAGE = "그룹을 등록하시겠습니까?";

/** 권한 그룹 수정 여부 메시지 */
export const PERMISSION_GROUP_UPDATE_POPUP_MESSAGE = "그룹을 수정하시겠습니까?";

/** 권한 그룹 삭제 여부 메시지 */
export const PERMISSION_GROUP_DELETE_POPUP_MESSAGE = "그룹을 삭제하시겠습니까?";

/** 권한 메뉴 경로 미등록 저장 여부 메시지 */
export const PERMISSION_MENU_SAVE_POPUP_MESSAGE = "메뉴를 경로없이 저장하시겠습니까?";

/** 권한 메뉴 등록 여부 메시지 */
export const PERMISSION_MENU_ADD_POPUP_MESSAGE = "메뉴를 등록하시겠습니까?";

/** 권한 메뉴 수정 여부 메시지 */
export const PERMISSION_MENU_UPDATE_POPUP_MESSAGE = "메뉴를 수정하시겠습니까?";

/** 권한 메뉴 삭제 여부 메시지 */
export const PERMISSION_MENU_DELETE_POPUP_MESSAGE = "메뉴를 삭제하시겠습니까?";

/** 그룹 메뉴 삭제 여부 메시지 */
export const PERMISSION_GROUP_MENU_DELETE_POPUP_MESSAGE = "메뉴를 그룹에서 삭제하시겠습니까?";

/** 그룹 메뉴 등록 여부 메시지 */
export const PERMISSION_GROUP_MENU_ALLOCATION_POPUP_MESSAGE = "메뉴를 그룹에 등록하시겠습니까?";

/** 그룹 메뉴 경로 미입력 저장 여부 메시지 */
export const PERMISSION_GROUP_MENU_PATH_WARNING_POPUP_MESSAGE = "메뉴에 경로가 무시됩니다. 진행하시겠습니까?";

/** 유저 권한 그룹 사용자 등록 여부 메시지 */
export const PERMISSION_USER_ADD_GROUP_POPUP_MESSAGE = "해당 사용자를 그룹에 포함하시겠습니까?";

/** 유저 권한 그룹 사용자 삭제 여부 메시지 */
export const PERMISSION_USER_DELETE_GROUP_POPUP_MESSAGE = "해당 사용자를 그룹에서 제외하시겠습니까?";

/** 유저 등록 여부 메시지 */
export const PERMISSION_USER_ADD_POPUP_MESSAGE = "사용자를 등록하시겠습니까?";

/** 유저 수정 여부 메시지 */
export const PERMISSION_USER_UPDATE_POPUP_MESSAGE = "사용자를 수정하시겠습니까?";

/** 유저 삭제 여부 메시지 */
export const PERMISSION_USER_DELETE_POPUP_MESSAGE = "사용자를 삭제하시겠습니까?";

/** 유저 비밀번호 재설정 현재 비밀번호 미입력 메시지 */
export const PERMISSION_USER_ORIGIN_EMPTY_PASSWORD_POPUP_MESSAGE = "현재 비밀번호를 입력하세요.";

/** 유저 비밀번호 재설정 현재 비밀번호 오류 메시지 */
export const PERMISSION_USER_WRONG_ORIGIN_PASSWORD_POPUP_MESSAGE = "현재 비밀번호가 올바르지 않습니다.";

/** 비밀번호 입력 오류 메시지 */
export const PERMISSION_USER_WRONG_PASSWORD_POPUP_MESSAGE = "비밀번호가 올바르지 않습니다.";

/** 유저 비밀번호 재설정 새 비밀번호 미입력 메시지 */
export const PERMISSION_USER_NEW_EMPTY_PASSWORD_POPUP_MESSAGE = "새 비밀번호를 입력하세요.";

/** 유저 비밀번호 재설정 새 비밀번호(확인용) 미입력 메시지 */
export const PERMISSION_USER_CONFIRM_EMPTY_PASSWORD_POPUP_MESSAGE = "새 비밀번호 (확인용) 을 입력하세요.";

/** 유저 비밀번호 재설정 새 비밀번호 규칙 위반 메시지 */
export const PERMISSION_USER_WRONG_NEW_PASSWORD_POPUP_MESSAGE = "새 비밀번호가 올바르지 않습니다.";

/** 유저 비밀번호 재설정 비밀번호 일치 경고 메시지 */
export const PERMISSION_USER_SAME_FORMER_PASSWORD_POPUP_MESSAGE = "새 비밀번호는 현재 비밀번호와 일치할 수 없습니다.";

/** 유저 비밀번호 재설정 비밀번호 미입력 메시지 */
export const PERMISSION_USER_RESET_EMPTY_PASSWORD_POPUP_MESSAGE = "비밀번호를 입력해주세요.";

/** 유저 비밀번호 재설정 비밀번호 변경 여부 메시지 */
export const PERMISSION_USER_RESET_PASSWORD_POPUP_MESSAGE = "비밀번호를 변경하시겠습니까?";

/** 유저 비밀번호 재설정 비밀번호 변경 완료 메시지 */
export const PERMISSION_USER_RESET_PASSWORD_SUCCESS_POPUP_MESSAGE = "비밀번호를 변경하였습니다.";

// #region EXTRA (prefix : EXTRA)

/** EXTRA DB 스키마 동기화 여부 메시지 */
export const EXTRA_DB_SCHEMA_SYNC_POPUP_MESSAGE = "DB 스키마를 동기화하시겠습니까?";

/** EXTRA DB 스키마 동기화 완료 메시지 */
export const EXTRA_DB_SCHEMA_SYNC_SUCCESS_POPUP_MESSAGE = "DB 스키마 동기화를 요청하였습니다.";

/** EXTRA 소스 동기화 여부 메시지 */
export const EXTRA_SOURCE_SYNC_POPUP_MESSAGE = "소스를 동기화하시겠습니까?";

/** EXTRA 소스 동기화 완료 메시지 */
export const EXTRA_SOURCE_SYNC_SUCCESS_POPUP_MESSAGE = "소스 동기화를 요청하였습니다.";

/** EXTRA 프론트엔드 빌드 진행 여부 메시지 */
export const EXTRA_FRONTEND_BUILD_POPUP_MESSAGE = "프론트엔드 빌드를 진행하시겠습니까?";

/** EXTRA 프론트엔드 빌드 진행 요청 완료 메시지 */
export const EXTRA_FRONTEND_BUILD_SUCCESS_POPUP_MESSAGE = "프론트엔드 빌드를 요청하였습니다.";

/** EXTRA 백엔드 빌드 진행 여부 메시지 */
export const EXTRA_BACKEND_BUILD_POPUP_MESSAGE = "백엔드 빌드를 진행하시겠습니까?";

/** EXTRA 백엔드 빌드 진행 요청 완료 메시지 */
export const EXTRA_BACKEND_BUILD_SUCCESS_POPUP_MESSAGE = "백엔드 빌드를 요청하였습니다.";

// #region Trend (prefix : Trend)

/** Trend 행 삭제 오류 메시지 */
export const TREND_NEW_INSERT_ROW_DELETE_POPUP_MESSAGE = "신규 등록된 행만 제거할 수 있습니다.";

// 관제점 대량 등록 (prefix : BULKPOINT)

/** 관제점 대량 등록 붙여넣기 오류 메시지 */
export const BULKPOINT_NO_COPY_DATA_POPUP_MESSAGE = "클립보드에서 붙여넣기 할 수 없습니다.";

/** 관제점 대량 등록 컬럼명 오류 메시지 */
export const BULKPOINT_COLUMN_NAME_POPUP_MESSAGE = "헤더라인의 컬럼명이 올바르지 않습니다.";

/** 관제점 대량 등록 구분자 오류 메시지 */
export const BULKPOINT_PARSING_ERROR_POPUP_MESSAGE =
    "구분자가 올바르지 않아 분석할 수 없습니다. 관리자에게 문의해 주세요.";

/** 관제점 대량 등록 붙여넣기 데이터 오류 메시지 */
export const BULKPOINT_COPY_NO_DATA_POPUP_MESSAGE = "클립보드에서 붙여넣기한 데이터가 존재하지 않습니다.";

/** 관제점 대량 등록 붙여넣기 데이터 분석 오류 메시지 */
export const BULKPOINT_COPY_PARSING_ERROR_POPUP_MESSAGE = "붙여넣기한 데이터를 분석할 수 없습니다.";

// XE (prefix : XE)

/** 제어 장비 오류 메시지 */
export const XE_CONTROL_EQUIP_POPUP_MESSAGE = "제어 가능한 장비가 없습니다.";

/** 출력 관제점 등록 여부 메시지 */
export const XE_PRINT_POINT_INSERT_POPUP_MESSAGE = "출력 관제점을 등록하시겠습니까?";

/** 출력 관제점 등록 완료 메시지 */
export const XE_PRINT_POINT_INSERT_SUCCESS_POPUP_MESSAGE = "출력 관제점을 등록하였습니다.";

// 계통 설비 관리 (prefix : SYSTEM_EQUIP)

/** 설비 제어 성공 메시지 */
export const SYSTEM_EQUIP_CONTROL_SUCCESS_MESSAGE = "제어에 성공하였습니다.";

/** 설비 제어 오류 메시지 */
export const SYSTEM_EQUIP_CONTROL_ERROR_MESSAGE = "제어에 실패하였습니다.";

/** 제어 설비 선택 Noti 메시지 */
export const SYSTEM_EQUIP_CONTROL_SELECT_NOTI_MESSAGE = "제어할 설비를 선택하세요.";

/** 설비 노드 추가 메시지 */
export const SYSTEM_EQUIP_NODE_ADD_MESSAGE = "설비 노드를 추가하였습니다.";

/** 계통 집계 활성화 메시지 */
export const SYSTEM_EQUIP_AGGREGATE_ACTIVE_MESSAGE = "계통 집계를 활성화하였습니다.";

/** 계통 집계 활성화 실패 메시지 */
export const SYSTEM_EQUIP_AGGREGATE_ACTIVE_ERROR_MESSAGE = "계통 집계 활성화에 실패하였습니다.";

/** 계통 집계 활성화 변경 메시지 */
export const SYSTEM_EQUIP_AGGREGATE_CHANGE_MESSAGE = (sysNodeName) =>
    `${sysNodeName}에 자동 집계가 활성화 되어 있습니다. 현재 노드로 집계를 활성화 하시겠습니까?`;

/** 노드 이동 메시지 */
export const SYSTEM_EQUIP_NODE_MOVE_MESSAGE = "노드를 이동하였습니다.";

/** 노드 이동 실패 메시지 */
export const SYSTEM_EQUIP_NODE_MOVE_ERROR_MESSAGE = "노드 이동에 실패하였습니다.";

/** 노드명 중복 메시지 */
export const SYSTEM_EQUIP_DUPLICATE_NODE_MESSAGE = "중복된 노드명이 존재합니다.";

/** 계통 활성화 메시지 */
export const SYSTEM_EQUIP_ACTIVE_MESSAGE = "계통을 활성화하였습니다.";

/** 관제점 중복 메시지 */
export const SYSTEM_EQUIP_DUPLICATE_POINT_MESSAGE = "중복된 관제점이 존재합니다.";

// 스케줄 제어 (prefix : SCHEDULE_CONTROL)

/** 제어 설비 유형 선택 */
export const SCHEDULE_CONTROL_SELECT_EQUIP_TYPE = (equipTypeName) =>
    `${equipTypeName} 유형을 사용합니다. 설비 그룹 또는 개별 설비를 설정해주세요.`;

/** 제어 설비 유형 선택 해제 */
export const SCHEDULE_CONTROL_UNSELECT_EQUIP_TYPE = (equipTypeName) => `${equipTypeName} 유형을 사용을 해제합니다.`;

/** 설비 그룹 선택 추가 Noti 메시지 */
export const SCHEDULE_CONTROL_SELECT_GROUP_ADD_NOTI_MESSAGE = "추가할 설비 그룹을 선택하세요.";

/** 설비 선택 추가 Noti 메시지 */
export const SCHEDULE_CONTROL_SELECT_EQUIP_ADD_NOTI_MESSAGE = "추가할 설비를 선택하세요.";

// 에너지 데이터 분석 (prefix : ENERGY_ANALYSIS)

/** 신규 입력시 삭제 할 수 없음 메시지 */
export const ENERGY_ANALYSIS_NEW_INSERT_NOT_DELETE_MESSAGE = "신규 입력시에는 삭제할 수 없습니다.";

/** 보고서 생성 완료 메시지 */
export const ENERGY_ANALYSIS_REPORT_CREATE_SUCCESS_MESSAGE = "보고서 생성이 완료되었습니다!";

/** 분석 방법 미선택 메시지 */
export const ENERGY_ANALYSIS_METHOD_SELECT_ERROR_MESSAGE = "분석 방법을 선택하세요.";

// 설비 관리 (prefix : EQUIP_MANAGE)

/** 모듈 인덱스 미선택 메시지 */
export const EQUIP_MANAGE_MODULE_INDEX_SELECT_ERROR_MESSAGE = "모듈 인덱스를 선택하세요.";

/** 모듈 저장 성공 && 모듈 연결 Noti 메시지 */
export const EQUIP_MANAGE_MODULE_SAVE_SUCCESS_CONNECT_NOTI_MESSAGE = "모듈을 저장하였습니다. 모듈을 연결해주세요.";

/** 모듈 연결 메시지 */
export const EQUIP_MANAGE_MODULE_CONNECT_MESSAGE = "모듈을 연결하였습니다.";

/** 모듈 연결 실패 메시지 */
export const EQUIP_MANAGE_MODULE_CONNECT_ERROR_MESSAGE = "모듈 연결에 실패하였습니다.";

/** 모듈 연결 해제 확인 메시지 */
export const EQUIP_MANAGE_MODULE_DISCONNECT_CONFIRM_MESSAGE = "모듈 연결을 해제하시겠습니까?";

/** 모듈 연결 해제 메시지 */
export const EQUIP_MANAGE_MODULE_DISCONNECT_MESSAGE = "모듈 연결을 해제하였습니다.";

/** 모듈 연결 해제 && 모듈 삭제 메시지 */
export const EQUIP_MANAGE_MODULE_DISCONNECT_DELETE_MESSAGE = "모듈 연결을 해제하고 삭제해 주세요.";

/** 빈 서브 모듈 없는 경우 메시지 */
export const EQUIP_MANAGE_EMPTY_SUB_MODULE_MESSAGE = "비어 있는 서브 모듈이 없습니다.";

/** 입력한 관제점 번호 미일치 메시지 */
export const EQUIP_MANAGE_NOT_MATCH_POINT_NUMBER_MESSAGE = "입력한 관제점 번호와 일치하는 내용이 없습니다.";

// 설비 유형 관리 (prefix : EQUIP_TYPE)

/** 설비 유형 참조 삭제 불가 메시지 */
export const EQUIP_TYPE_REFERENCE_DELETE_NOT_MESSAGE =
    "설비유형코드를 참조하고 있는 다른 객체가 있는 경우 삭제할 수 없습니다.";

// 계통 유형 코드 관리 (prefix : SYSTEM_TYPE_CODE)

/** 계통 코드 필수 입력 메시지 */
export const SYSTEM_TYPE_CODE_REQUIRED_MESSAGE = "계통 코드는 필수 입력 항목입니다.";

/** 계통 코드명 필수 입력 메시지 */
export const SYSTEM_TYPE_NAME_REQUIRED_MESSAGE = "계통 코드명은 필수 입력 항목입니다.";
