<template>
    <b-modal ref="setPointListOptionsModal" size="md" @hidden="onClose" title="표시항목 선택">
     
        <template #default>

            <!-- 안내 메시지 -->
            <div class="mb-2">
                <span class="guide-message">조회 결과에 표시할 항목을 선택하세요.</span>
            </div>

            <!-- 전체 선택 및 전체 해제 버튼 그룹 -->
            <div class="input-group mb-2 d-flex justify-content-end w-100">
                <button type="button" class="btn btn-primary" @click="selectAll">전체 선택</button>
                <button type="button" class="btn btn-secondary" @click="deselectAll">전체 해제</button>
            </div>

            <!-- <div class="input-group">
                <input type="text" class="form-control" placeholder="Recipient's username"
                    aria-label="Recipient's username with two button addons" readonly>
                <button class="btn btn-outline-secondary" type="button">Button</button>
                <button class="btn btn-outline-secondary" type="button">Button</button>
            </div> -->

            <!-- 표시항목 목록 표시 -->
                <div v-if="pointListOptions" class="form-group scrollable-content">
                    <div v-for="(list, index) in pointListOptions" :key="index" class="input-group mb-2">
                        <!-- 라벨 (표시항목) -->
                        <!-- <div class="input-group-prepend">
                            <span class="input-group-text custom-prepend">{{ list.text }}</span>
                        </div> -->
                        <input type="text" class="form-control" :placeholder="`${list.text} (${list.key})`" readonly>

                        <!-- 버튼 그룹 -->
                        <button type="button" class="btn" :class="list.checked ? 'btn-primary ' : 'btn-outline-secondary'"
                            @click="setVisibility(index, true)" :disabled="list.key == 'ptIdx' || list.key == 'pointAddress'"> 표시 </button>
                        <button type="button" class="btn" :class="!list.checked ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="setVisibility(index, false)" :disabled="list.key == 'ptIdx' || list.key == 'pointAddress'"> 숨김 </button>
                    </div>
                </div>
        </template>

        <!-- Footer 영역 -->
        <template #modal-footer>
            <div class="d-flex">
                <button type="button" class="btn btn-primary flex-fill mr-1" @click="onConfirm">적용</button>
                <button type="button" class="btn btn-secondary flex-fill ml-1" @click="closeModal">닫기</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {},
    mounted() {
        this.pointListOptions = this.$store.state.pointListOptions;
    },
    data() {
        return {
            pointListOptions: null,
        }
    },
    methods: {
        setVisibility(index, value) {
            // 해당 항목의 표시/숨김 상태를 설정
            this.$set(this.pointListOptions, index, {
                ...this.pointListOptions[index],
                checked: value
            });
        },
        selectAll() {
            this.pointListOptions.forEach((item, index) => {
                this.setVisibility(index, true);
            });
        },
        deselectAll() {
            this.pointListOptions.forEach((item, index) => {
                this.setVisibility(index, false);
            });
        },
        showModal() {
            this.$refs.setPointListOptionsModal.show();
        },
        closeModal() {
            this.$refs.setPointListOptionsModal.hide();
        },
        async onConfirm() {
            try {
                await this.$store.commit("SET_POINTLIST_OPTIONS", this.pointListOptions);
    
                this.$emit('confirm');
                this.closeModal();                
            } catch (e) {
                console.error(e)
            }
        },
        onClose() {
            this.$emit('hidden');
        },
    }
}
</script>

<style scoped>
/* 안내 메시지 스타일 */
.guide-message {
    font-size: 13px;
    color: #333;
}

/* 스크롤 및 최대 높이 설정 */
.scrollable-content {
    max-height: 40em;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-right: 1rem;
    /* 우측 패딩 추가 */
}
</style>
