<template>
    <ul class="nav" id="sidebar-nav-profile">
        <b-tooltip :target="'sidebar-nav-profile'" :title="$t('시스템 정보')" :triggers="'hover'"></b-tooltip>
        <li class="nav-profile">
            <!-- v-b-tooltip.hover
			:title="$t('시스템 정보')" -->
            <a id="nav-profile" href="javascript:;" v-on:click="onBuildingIconClick()" :style="{ cursor: 'pointer' }">
                <div v-if="$theme != 'facebook'" class="cover with-shadow"></div>
                <div
                    class="image image-icon bg-black text-grey-darker"
                    :class="{ 'image-icon bg-black text-grey-darker': $theme == 'default' }"
                >
                    <i class="fa fa-building"></i>
                </div>

                <div class="info">
                    <!-- v-xe-pm.E.A  권한은 아래 버튼 <li> 태그의 권한과 동일해야 함.. -->
                    <b v-xe-pm.E.A class="pull-right" :class="{ caret: false }"></b>
                    <!-- {{ "CxEMS v2.1" }} -->
                    <!-- <small>{{ "Carbon Free Energy Management Solution" }}</small> -->
                    {{ master.brand }}
                    <small>{{ master.description }}</small>
                </div>
            </a>
        </li>

        <li v-xe-pm.E.A>
            <ul
                class="nav nav-profile"
                v-bind:class="{
                    'd-block': this.stat == 'expand' && !this.pageOptions.pageSidebarMinified,
                    'd-none': this.stat == 'collapse',
                }"
            >
                <li>
                    <a href="javascript:;" @click="onClickNavProfile"><i class="fa fa-cog"></i> Edit </a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
//import PageOptions from "../../config/PageOptions.vue";

export default {
    name: "SidebarNavProfile",
    props: ["click-profile"],
    data() {
        return {
            stat: "",
            //pageOptions: PageOptions,
        };
    },
    computed: {
        master() {
            return this.$store.getters.master;
        },
    },
    methods: {
        onClickNavProfile() {
            this.$emit("click-profile");
        },
        onBuildingIconClick: function() {
            // NavProfile 보이기 숨기기
            //this.stat = (this.stat == 'expand') ? 'collapse' : 'expand'

            this.$emit("click-profile");
        },
    },
};
</script>
